import FlyerCloud from "./cloud";

export default class FlyerRepository {

  public searchFlyers = (payload: any) => {
    return FlyerCloud.searchFlyers(payload);
  };

  public createFlyer = (payload: any) => {
    return FlyerCloud.createFlyer(payload);
  };

  public addCustomPrice = (payload: any) => {
    return FlyerCloud.addCustomPrice(payload);
  };

  public fetchMaxSeat = (payload: any) => {
    return FlyerCloud.fetchMaxSeat(payload);
  };

  public updateFlyer = (payload: any) => {
    return FlyerCloud.updateFlyer(payload);
  };

  public deleteFlyer = (payload: any) => {
    return FlyerCloud.deleteFlyer(payload);
  };

  public importFlyer = (payload: any) => {
    return FlyerCloud.importFlyer(payload);
  };

  public registerFlyer = (payload: any) => {
    return FlyerCloud.registerFlyer(payload);
  };

  public getImporFlyerHistory = (payload: any) => {
    return FlyerCloud.getImporFlyerHistory(payload);
  };
}
