import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportOrdersV2UseCase } from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, exportOrdersV2Fail, exportOrdersV2Success } from "../../actions/orders/order.actions";
import { notifyError, notifySuccess } from "../../../../utils/toast";
export const exportOrdersV2Epic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.EXPORT_ORDERS_V2),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportOrdersV2UseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('エクスポート成功!');
                    obs.next(exportOrdersV2Success(data.data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(exportOrdersV2Fail(error.message));
                    obs.complete();
                });
            });
        })
    );


