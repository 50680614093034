import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, createInvoiceFail, createInvoiceSuccess, publishInvoicesFail, publishInvoicesSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { CreateInvoiceUseCase, PublishInvoicesUseCase } from "../../../../domain/usecases/invoices";
import { IPublishInvoicesPayload } from "../../actions/interfaces/invoices.types";


export const publishInvoicesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.PUBLISH_INVOICES),
        exhaustMap((action: IAction<IPublishInvoicesPayload>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new PublishInvoicesUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(publishInvoicesSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(publishInvoicesFail(error.message));
                    notifyError(error.message);
                    obs.complete();
                });
            });
        })
    );


