import React, { lazy, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './presentation/components/AccessibleNavigationAnnouncer';
import PrivateRoute from './presentation/components/login/PrivateRoute';
import { useDispatch } from 'react-redux';
import { initApplication } from './presentation/redux/actions/general/appInitiation';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useQuery } from 'react-query';
import CustomerCloud from './data/repository/customer/cloud';
import FullLoading from './pages/invoices/FullLoading';

const firebaseConfig = {
  apiKey: "AIzaSyC80QSIcIMDOhPUIzm8NFA2bUyuTu7bZew",
  authDomain: "urry-order-dev.firebaseapp.com",
  projectId: "urry-order-dev",
  storageBucket: "urry-order-dev.appspot.com",
  messagingSenderId: "732616166580",
  appId: "1:732616166580:web:135980838c9a111aec9be1",
  measurementId: "G-8NJSLSL17M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Layout = lazy(() => import('./presentation/layout/Layout'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Maintain = lazy(() => import('./pages/Maintain'));

const App = () => {
  const dispatch = useDispatch();
  const [isMaintainMode, setIsMaintainMode] = useState(false);
  // const {} =

  const {data: maintainData, isLoading } = useQuery({
    queryKey: ['getMaintainMode'],
    queryFn:CustomerCloud.getMaintainMode,
    onSuccess: data => {
      const ignoreMaintainMode = localStorage.getItem('ignoreMaintainMode');
      if(data?.success && data?.data?.underMaintenance && ignoreMaintainMode !== 'true'){
        setIsMaintainMode(true)
      } else {
        setIsMaintainMode(false)
      }
    },
    onError: () => {
      setIsMaintainMode(false)
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 10, //cache 10 minute
  })

  React.useEffect(() => {
    dispatch(initApplication());

  },[]);

  if(isLoading){
    return <FullLoading />
  }

  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>

          {isMaintainMode ? (
            <>
              <Route path="/maintain" component={() => <Maintain {...maintainData?.data} />} />
              <Redirect exact from='/*' to="/maintain" />

            </>
          ) : !isLoading ? (
            <>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/forgot-password" component={ForgetPassword} />
              <Route path="/update-password" component={UpdatePassword} />
              <Route path="/reset-password/:token/:email" component={ResetPassword} />

              <PrivateRoute >
                <Route path="/" component={Layout} />
              </PrivateRoute>
            </>
          ) : null}
        </Switch>
      </Router>
    </>
  );
};

export default App;
