import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { GetActiveCustomersUseCase, SetProductVisibleUseCase } from "../../../../domain/usecases/products";
import SetProductPriorityUseCase from "../../../../domain/usecases/products/SetProductPriorityUseCase";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, getActiveCustomersSuccess, getActiveCustomersFail, setProductVisibleSuccess, setProductVisibleFail, setProductPrioritySuccess, setProductPriorityFail } from "../../actions/products/product.actions";


export const setProductPriorityEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SET_PRODUCT_PRIORITY),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SetProductPriorityUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    notifySuccess("表示順が更新されました!");
                    obs.next(setProductPrioritySuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(setProductPriorityFail(error.message));
                    obs.complete();
                });
            });
        })
    );


