import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, searchInvoicesFail, searchInvoicesSuccess, sendInvoiceReminderFail, sendInvoiceReminderSuccess, switchInvoiceStatusFail, switchInvoiceStatusSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { SendInvoiceReminderUseCase, SwitchInvoiceStatusUseCase } from "../../../../domain/usecases/invoices";


export const switchInvoiceStatusEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.SWITCH_INVOICE_STATUS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SwitchInvoiceStatusUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(switchInvoiceStatusSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(switchInvoiceStatusFail(error.message));
                    obs.complete();
                });
            });
        })
    );


