import StorageGateway from '../../../gateway/storage';
import { TokenType, User } from '../../../constants';
import ResponseModel from '../../../../models/response';


const setAuthToken = (token: string) => {
    return StorageGateway.doUpdate(TokenType.AccessToken, token);
};

const saveResetToken = (token: string) => {
    return StorageGateway.doUpdate(TokenType.ResetToken, token);
};

const setRefreshToken = (token: string) => {
    return StorageGateway.doUpdate(TokenType.RefreshToken, token);
};

const getAuthToken = async (): Promise<ResponseModel<string>> => {
    return StorageGateway.doGet(TokenType.AccessToken);
};

const removeAuthToken = () => {
    return StorageGateway.doDelete(TokenType.AccessToken);
};

/**
 *
 * @param deviceToken
 */
const saveDeviceToken = (deviceToken: string): Promise<ResponseModel<boolean>> => {
    return StorageGateway.doUpdate(TokenType.DeviceToken, deviceToken);
}

/**
 *
 */
const getDeviceToken = (): Promise<ResponseModel<string>> => {
    return StorageGateway.doGet(TokenType.DeviceToken);
}

/**
 *
 */
const removeDeviceToken = (): Promise<ResponseModel<boolean>> => {
    return StorageGateway.doDelete(TokenType.DeviceToken);
}

const setUserProfile = (token: string) => {
    return StorageGateway.doUpdate(TokenType.UserInfo, token);
};

/**
 *
 */
 const getUserProfile = (): Promise<ResponseModel<string>> => {
    return StorageGateway.doGet(TokenType.UserInfo);
}



export default {
    setAuthToken,
    saveResetToken,
    setRefreshToken,
    setUserProfile,
    getUserProfile,
    getAuthToken,
    removeAuthToken,
    saveDeviceToken,
    getDeviceToken,
    removeDeviceToken
};

