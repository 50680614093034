import { ofType } from "redux-observable";
import {
  CustomerActionTypes,
  updateCustomerHidePriceFail,
  updateCustomerHidePriceSuccess,
} from "../../actions/customers/customer.actions";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { Observable } from "rxjs";
import UpdateCustomerHidePriceUseCase from "../../../../domain/usecases/customer/hideCustomerPrices";
import { notifySuccess } from "../../../../utils/toast";

export const updateCustomerHidePriceEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE),
    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;
        const useCase = new UpdateCustomerHidePriceUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            let data = response;

            obs.next(updateCustomerHidePriceSuccess(data.data));

            notifySuccess("データを更新しました");

            obs.complete();
          })
          .catch((error) => {
            obs.next(updateCustomerHidePriceFail(error.message));

            obs.complete();
          });
      });
    })
  );
