import { ofType } from "redux-observable";
import { IAction } from "../../../../shared/interfaces/common";
import { exhaustMap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  SaleActionTypes,
  onUpdateSaleSuccess,
  updateSaleFail,
  updateSaleSuccess,
} from "../../actions/sales/sale.actions";
import { UpdateSaleUseCase } from "../../../../domain/usecases/sales";

export const updateSaleEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SaleActionTypes.UPDATE_SALE),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new UpdateSaleUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(onUpdateSaleSuccess(data.data));
            obs.next(updateSaleSuccess(data.data));
            obs.complete();
          })
          .catch((error) => {
            obs.next(updateSaleFail(error.message));
            obs.complete();
          });
      });
    })
  );
