import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { SetActivePriceUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, setActivePriceSuccess, setActivePriceFail } from "../../actions/products/product.actions";


export const setActivePriceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SET_ACTIVE_PRICE),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SetActivePriceUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    // notifySuccess('販売開始しました！');
                    obs.next(setActivePriceSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(setActivePriceFail(error.message));
                    obs.complete();
                });
            });
        })
    );


