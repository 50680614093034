import SaleRepository from "../../../../data/repository/sale";
import ResponseModel from "../../../../models/response";
import ValidationResult from "../../../../models/validationResult";
import { IAddSaleRepresentativePayload } from "../../../../presentation/redux/actions/interfaces/saleInfo.types";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";
import { notifyError, notifySuccess } from "../../../../utils/toast";

export default class AddSaleRepresentativeUseCase implements IUseCase {
  private saleRepository: SaleRepository;

  constructor(public payload: IAddSaleRepresentativePayload) {
    this.saleRepository = new SaleRepository();
  }

  validate = (): ValidationResult => {
    return new ValidationResult(true);
  };

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      const validationResult = this.validate();

      if (validationResult.isValid) {
        this.saleRepository
          .addSaleRepresentative(this.payload)
          .then((response: any) => {
            if (response.data && response.data.data && response.data.success) {
              notifySuccess("Successfully Added!");

              resolve(response.data);
            } else {
              if (response.data && response.data.errorMessage) {
                notifyError(response.data.errorMessage);
              }

              reject(
                ResponseModel.createError(500, response.data.errorMessage)
              );
            }
          })
          .catch(reject);
      }
    });
  };
}
