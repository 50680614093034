import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, deleteInvoiceFail, deleteInvoiceSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { DeleteInvoiceUseCase } from "../../../../domain/usecases/invoices";


export const deleteInvoiceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.DELETE_INVOICE),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new DeleteInvoiceUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(deleteInvoiceSuccess(data.data));
                    notifySuccess('請求書が削除されました');
                    obs.complete();
                }).catch(error => {
                    obs.next(deleteInvoiceFail(error.message));
                    notifyError('請求書を削除できませんでした。再度お試しください');
                    obs.complete();
                });
            });
        })
    );


