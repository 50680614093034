import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { ICompleteUploadPayload, ICreateBulkInvoiceJobPayload, IDownloadInvoiceBillPayload, IGetBulkUploadHistoriesPayload, IGetInvoiceListPayload, IGetInvoicePayload, IGetSignedUrlPayload, IPublishInvoicesPayload, ISearchInvoicesPayload, ISingleInvoicePayload, ISwitchInvoiceStatusPayload } from '../../../../presentation/redux/actions/interfaces/invoices.types';

const searchInvoices = (payload: ISearchInvoicesPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.SearchInvoices(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(invoiceResource);
};

const hasPendingJob = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.HasPendingJob();
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(invoiceResource);
};

const createInvoice = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.CreateInvoice();
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(invoiceResource, payload);
};

const createInvoiceVersion = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.CreateInvoiceVersion();
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(invoiceResource, payload);
};

const deleteInvoice = (payload: ISingleInvoicePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.DeleteInvoice(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doDeleteRequest(invoiceResource);
};

const restoreInvoice = (payload: ISingleInvoicePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.RestoreInvoice(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPutRequest(invoiceResource, payload);
};

const downloadInvoice = (payload: ISingleInvoicePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.DownloadInvoice(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(invoiceResource);
};

const sendInvoiceReminder = (payload: ISingleInvoicePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.SendInvoiceReminder(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(invoiceResource, payload);
};

const createBulkUploadJob = (payload: ICreateBulkInvoiceJobPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.CreateBulkUploadJob(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(invoiceResource, payload);
};

const publishInvoices = (payload: IPublishInvoicesPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.PublishInvoices(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(invoiceResource, payload);
};

const getBulkUploadHistories = (payload: IGetBulkUploadHistoriesPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.GetBulkUploadHistories(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(invoiceResource);
};

const switchInvoiceStatus = (payload: ISwitchInvoiceStatusPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.SwitchInvoiceStatus(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPutRequest(invoiceResource, payload);
};

const getSignedUrl = (payload: IGetSignedUrlPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.GetSignedUrl(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(invoiceResource);
};

const completeUpload = (payload: ICompleteUploadPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.CompleteUpload(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPutRequest(invoiceResource, payload);
};

const getInvoiceDetail = (payload: IGetInvoicePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.GetInvoiceDetail(payload);
    return apiGateway.doGetRequest(invoiceResource);
};

const getInvoiceList = (payload: IGetInvoiceListPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.GetInvoiceList(payload);
    return apiGateway.doGetRequest(invoiceResource);
};

const downloadInvoiceBill = (payload: IDownloadInvoiceBillPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const invoiceResource = SitecoreResource.Invoice.DownloadInvoiceBill(payload);
    return apiGateway.doGetRequest(invoiceResource);
};

export default {
    searchInvoices,
    createInvoice,
    createInvoiceVersion,
    deleteInvoice,
    restoreInvoice,
    downloadInvoice,
    sendInvoiceReminder,
    createBulkUploadJob,
    hasPendingJob,
    publishInvoices,
    getBulkUploadHistories,
    switchInvoiceStatus,
    getSignedUrl,
    completeUpload,
    getInvoiceDetail,
    getInvoiceList,
    downloadInvoiceBill
};
