import { combineReducers } from "@reduxjs/toolkit";
import { searchFlyersReducer } from "./searchFlyersReducer";
import { createFlyersReducer } from "./createFlyersReducer";
import { addCustomPriceReducer } from "./addCustomPriceReducer";
import { fetchMaxSeatReducer } from "./fetchMaxSeatReducer";
import { updateFlyersReducer } from "./updateFlyersReducer";
import { deleteFlyersReducer } from "./deleteFlyersReducer";
import { importFlyersReducer } from "./importFlyersReducer";
import { registerFlyerReducer } from "./registerFlyerReducer";
import { getImportFlyerHistoryReducer } from "./getImportFlyerHistoryReducer";

export const flyersReducer = combineReducers({
  searchFlyersReducer,
  createFlyersReducer,
  addCustomPriceReducer,
  fetchMaxSeatReducer,
  updateFlyersReducer,
  deleteFlyersReducer,
  importFlyersReducer,
  registerFlyerReducer,
  getImportFlyerHistoryReducer
});
