import { IUseCase } from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import NotificationRepository from '../../../data/repository/notification';
import { notifyError, notifySuccess } from '../../../utils/toast';

export default class UploadPriceNotificationUseCase implements IUseCase {
    private notificationRepository: NotificationRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.notificationRepository = new NotificationRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.notificationRepository.uploadPriceNotificationRepository(this.payload).then((response: any) => {
                if (response.data.success) {
                    notifySuccess("成功")
                    resolve(response.data);
                } else {
                    if(response.data.data.errors.some((x: any) =>x == 'WrongFormat' || x == '無効なヘッダーです')){
                        notifyError("受付できないファイルタイプです")
                    }else{
                        notifyError("失敗")
                    }
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
