import {gql} from 'graphql-request';

export const getNotAssignReasonsQuery = () => {
    return gql`
    query {
      result:reasons{
        id
        name
        color{
          hex
          rgba{
            r
            g
            b
            a
          }  
        }
      }
    }`;
  }

 

  