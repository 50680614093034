import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, getProductsSelectedSuccess, getProductsSelectedFail } from "../../actions/orders/order.actions";
import GetProductsSelectedUseCase from "../../../../domain/usecases/order/GetProductsSelectedUseCase";
import ApiHelper from "../../../../data/helper/ApiHelper";

export const getProductsSelectedEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.GET_PRODUCTS_SELECTED),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetProductsSelectedUseCase(payload);
                usecase.execute().then((responses: any) => {
                    var data = responses.data;
                    data.forEach( (item:any) => {
                        if(item.prices.length > 0){
                            item.prices = ApiHelper.getPrices(item);
                        }
                    });
                    obs.next(getProductsSelectedSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getProductsSelectedFail(error.message));
                    obs.complete();
                });
            });
        })
    );


