import { IAction } from "../../../../shared/interfaces/common";
import { IGetUserPayload, IUpdateSakayaInfoPayload } from "../interfaces/users.types";

// User Login
export enum UserActionTypes {
  USER_LOGIN = "[Users] USER_LOGIN",
  USER_LOGIN_SUCCESS = "[Users] USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL = "[Users] USER_LOGIN_FAIL",
  GET_USER_DETAIL = "[Users] GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "[Users] GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAIL = "[Users] GET_USER_DETAIL_FAIL",
  DELETE_USER = "[Users] DELETE_USER",
  DELETE_USER_SUCCESS = "[Users] DELETE_USER_SUCCESS",
  DELETE_USER_FAIL = "[Users] DELETE_USER_FAIL",

  USER_UPDATE_PASSWORD = "[Users] USER_UPDATE_PASSWORD",
  USER_UPDATE_PASSWORD_SUCCESS = "[Users] USER_UPDATE_PASSWORD_SUCCESS",
  USER_UPDATE_PASSWORD_FAIL = "[Users] USER_UPDATE_PASSWORD_FAIL",

  USER_RESET_PASSWORD = "[Users] USER_RESET_PASSWORD",
  USER_RESET_PASSWORD_SUCCESS = "[Users] USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAIL = "[Users] USER_RESET_PASSWORD_FAIL",

  GET_USERS = "[Users] GET_USERS",
  GET_USERS_SUCCESS = "[Users] GET_USERS_SUCCESS",
  GET_USERS_FAIL = "[Users] GET_USERS_FAIL",

  ADD_USER = "[Users] ADD_USER",
  ADD_USER_SUCCESS = "[Users] ADD_USER_SUCCESS",
  ADD_USER_FAIL = "[Users] ADD_USER_FAIL",

  UPDATE_USER = "[Users] UPDATE_USER",
  UPDATE_USER_SUCCESS = "[Users] UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL = "[Users] UPDATE_USER_FAIL",

  GET_USER_DETAIL_STORAGE = "[Users] GET_USER_DETAIL_STORAGE",
  GET_USER_DETAIL_STORAGE_SUCCESS = "[Users] GET_USER_DETAIL_STORAGE_SUCCESS",
  GET_USER_DETAIL_STORAGE_FAIL = "[Users] GET_USER_DETAIL_STORAGE_FAIL",

  GET_SAKAYA_INFO = "[Users] GET_SAKAYA_INFO",
  GET_SAKAYA_INFO_SUCCESS = "[Users] GET_SAKAYA_INFO_SUCCESS",
  GET_SAKAYA_INFO_FAIL = "[Users] GET_SAKAYA_INFO_FAIL",

  UPDATE_SAKAYA_INFO = "[Users] UPDATE_SAKAYA_INFO",
  UPDATE_SAKAYA_INFO_SUCCESS = "[Users] UPDATE_SAKAYA_INFO_SUCCESS",
  UPDATE_SAKAYA_INFO_FAIL = "[Users] UPDATE_SAKAYA_INFO_FAIL",

}

export interface ILoginPayload {
  email: string;
  password: string;
}
export interface IUserPayload {
  username: string;
  email: string;
  roles?: string[];
}

export interface UserUpdatePasswordPayload {
  email: string;
  currentPassword: string;
  newPassword: string;
}

export interface UserResetPasswordPayload {
  email: string;
  token: string;
  newPassword: string;
}



export const userLogin = (payload?: any): IAction<any> => ({
  type: UserActionTypes.USER_LOGIN,
  payload
});


export const userLoginSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.USER_LOGIN_SUCCESS,
  payload
});

export const userLoginFail = (error: any): IAction<any> => ({
  type: UserActionTypes.USER_LOGIN_FAIL,
  error
});


export const getUserDetail = (id: string = ''): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL,
  payload: {id}
});


export const getUserDetailSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL_SUCCESS,
  payload
});

export const getUserDetailFail = (error: any): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL_SUCCESS,
  error
});

export const getUserDetailStorage = (): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL_STORAGE,
});


export const getUserDetailStorageSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL_STORAGE_SUCCESS,
  payload
});

export const getUserDetailStorageFail = (error: any): IAction<any> => ({
  type: UserActionTypes.GET_USER_DETAIL_STORAGE_FAIL,
  error
});


//User update and reset password
export const userUpdatePassword = (payload?: UserUpdatePasswordPayload): IAction<any> => ({
  type: UserActionTypes.USER_UPDATE_PASSWORD,
  payload
});


export const userUpdatePasswordSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.USER_UPDATE_PASSWORD_SUCCESS,
  payload
});

export const userUpdatePasswordFail = (error: any): IAction<any> => ({
  type: UserActionTypes.USER_UPDATE_PASSWORD_FAIL,
  error
});

export const userResetPassword = (payload?: UserResetPasswordPayload): IAction<any> => ({
  type: UserActionTypes.USER_RESET_PASSWORD,
  payload
});


export const userResetPasswordSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.USER_RESET_PASSWORD_SUCCESS,
  payload
});

export const userResetPasswordFail = (error: any): IAction<any> => ({
  type: UserActionTypes.USER_RESET_PASSWORD_FAIL,
  error
});



export const getUsers = (payload: IGetUserPayload): IAction<any> => ({
  type: UserActionTypes.GET_USERS,
  payload
});


export const getUsersSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.GET_USERS_SUCCESS,
  payload
});

export const getUsersFail = (error: any): IAction<any> => ({
  type: UserActionTypes.GET_USERS_FAIL,
  error
});


export const addUser = (payload: any): IAction<any> => ({
  type: UserActionTypes.ADD_USER,
  payload
});

export const addUserSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.ADD_USER_SUCCESS,
  payload
});

export const addUserFail = (error: any): IAction<any> => ({
  type: UserActionTypes.ADD_USER_FAIL,
  error
});

export const getSakayaInfo = (): IAction<any> => ({
  type: UserActionTypes.GET_SAKAYA_INFO,
});


export const getSakayaInfoSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.GET_SAKAYA_INFO_SUCCESS,
  payload
});

export const getSakayaInfoFail = (error: any): IAction<any> => ({
  type: UserActionTypes.GET_SAKAYA_INFO_SUCCESS,
  error
});

export const updateSakayaInfo = (payload: IUpdateSakayaInfoPayload): IAction<any> => ({
  type: UserActionTypes.UPDATE_SAKAYA_INFO,
  payload
});

export const updateSakayaInfoSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.UPDATE_SAKAYA_INFO_SUCCESS,
  payload
});

export const updateSakayaInfoFail = (error: any): IAction<any> => ({
  type: UserActionTypes.UPDATE_SAKAYA_INFO_FAIL,
  error
});

export const deleteUser = (id: string): IAction<any> => ({
  type: UserActionTypes.DELETE_USER,
  payload: {id}
});


export const deleteUserSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.DELETE_USER_SUCCESS,
  payload
});

export const deleteUserFail = (error: any): IAction<any> => ({
  type: UserActionTypes.DELETE_USER_FAIL,
  error
});

export const updateUser = (payload: any): IAction<any> => ({
  type: UserActionTypes.UPDATE_USER,
  payload
});

export const updateUserSuccess = (payload?: any): IAction<any> => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
  payload
});

export const updateUserFail = (error: any): IAction<any> => ({
  type: UserActionTypes.UPDATE_USER_FAIL,
  error
});