import { ActionStatus } from '../../../shared/constants';
import { DEFAULT_SECTION_ID } from '../../../shared/constants';
import { IAction, IState, IDataList, IStateSectionList, IStateForList } from '../../../shared/interfaces/common';

export default class ReducerHelper {
    static DEFAULT_SECTION_KEY = 'default';

    /**
     * For common usage
     * @returns {{isFetching: boolean, params: null, data: null, error: null, success: boolean, actionType: string}}
     */
    static baseReducer = (data?: any): IState<any> => ({
        isFetching: false,
        params: undefined,
        data: data,
        errorMessage: undefined,
        errorCode: undefined,
        success: false,
        actionType: '',
        status: ActionStatus.NONE
    });

    static baseListReducer = (data?: IDataList<any>): IState<IDataList<any>> => ({
        isFetching: false,
        params: undefined,
        data: data ? data : {
            byId: {},
            ids: []
        },
        errorMessage: undefined,
        errorCode: undefined,
        success: false,
        actionType: '',
        status: ActionStatus.NONE
    });

    static baseSectionListReducer = (): IStateSectionList<any> => ({
        isFetching: false,
        params: undefined,
        data: {},
        errorMessage: undefined,
        errorCode: undefined,
        success: false,
        actionType: '',
        status: ActionStatus.NONE
    });

    static baseProcessSuccess = (state: IState<any>, action: IAction<any>) => {
        return {
            ...state,
            status: ActionStatus.DONE,
            isFetching: false,
            data: action.payload,
            errorMessage: undefined,
            errorCode: undefined,
            success: true,
            actionType: action.type
        };
    };

    static baseProcessRequest = (state: IState<any>, action: IAction<any>) => {
        return {
            ...state,
            status: action.params && action.params.refresh ? ActionStatus.REFRESHING : ActionStatus.FETCHING,
            isFetching: true,
            params: action.payload,
            errorMessage: undefined,
            errorCode: undefined,
            success: false,
            actionType: action.type
        };
    };

    static baseProcessFailed = (state: IState<any>, action: IAction<any>) => {
        return {
            ...state,
            status: ActionStatus.DONE,
            isFetching: false,
            errorMessage: action.error?.errorMessage ?? action.error,
            errorCode: action.error?.errorCode ?? undefined,
            success: false,
            actionType: action.type
        };
    };

    /**
     * @description
     * @static
     * @param isAppend: data list should be appended or just replace the data
     * @memberof ReducerHelper
     */
    static baseProcessForListSuccess = (state: IStateForList<any>, action: IAction<IDataList<any>>, isAppend?: boolean) => {
        let newData = Object.assign({}, state.data);
        const listData: IDataList<any> = newData ? newData : { byId: {}, ids: [] };
        const byId = action.payload ? action.payload.byId : {};
        const ids = action.payload ? action.payload.ids : [];

        if (isAppend) {
            listData.ids = listData.ids.concat(ids);
            listData.byId = {
                ...listData.byId,
                ...byId
            };
        } else {
            listData.ids = ids;
            listData.byId = byId;
        }

        newData = listData;
        const result: IStateForList<any> = {
            ...state,
            status: ActionStatus.DONE,
            isFetching: false,
            data: newData,
            errorMessage: undefined,
            errorCode: undefined,
            success: true,
            actionType: action.type,
            canLoadMore: action.params ? !!action.params.canLoadMore : true
        };

        return result;
    };

    /**
     * @description
     * @static
     * @param isAppend: data list should be appended or just replace the data
     * @memberof ReducerHelper
     */
    static baseProcessForSectionListSuccess = (state: IStateSectionList<any>, action: IAction<IDataList<any>>, isAppend?: boolean) => {
        const params = action.params;
        const sectionId = params && params.sectionId ? params.sectionId : DEFAULT_SECTION_ID;
        const newData = Object.assign({}, state.data);
        const listData: IDataList<any> = newData[sectionId] ? newData[sectionId] : { byId: {}, ids: [] };
        const byId = action.payload ? action.payload.byId : {};
        const ids = action.payload ? action.payload.ids : [];
        if (isAppend) {
            listData.ids = listData.ids.concat(ids);
            listData.byId = {
                ...listData.byId,
                ...byId
            };
        } else {
            listData.ids = ids;
            listData.byId = byId;
        }
        newData[sectionId] = listData;
        // console.log(newData)
        return {
            ...state,
            status: ActionStatus.DONE,
            isFetching: false,
            data: newData,
            errorMessage: undefined,
            errorCode: undefined,
            success: true,
            actionType: action.type,
            canLoadMore: action.params ? {
                ...state.canLoadMore,
                [sectionId]: !!action.params.canLoadMore
            } : { ...state.canLoadMore }
        };
    };
}
