const TYPE_ADMIN = 'admin';
const TYPE_CUSTOMER = 'customer';
const TYPE_PUBLIC = 'public';
const TYPE_GOOGLE_MAPS = 'google_maps';
const TYPE_LOGIN = 'login';

export default {
    Public: TYPE_PUBLIC,
    Admin: TYPE_ADMIN,
    Customer: TYPE_CUSTOMER,
    GoogleMaps: TYPE_GOOGLE_MAPS,
    Login: TYPE_LOGIN,
};
