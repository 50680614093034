import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, scanFaxImagesSuccess, scanFaxImagesFail } from "../../actions/orders/order.actions";
import ApiHelper from "../../../../data/helper/ApiHelper";
import { ScanFaxImagesUseCase } from "../../../../domain/usecases/order";

export const scanFaxImagesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.SCAN_FAX_IMAGES),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ScanFaxImagesUseCase(payload);
                usecase.execute().then((responses: any) => {
                    obs.next(scanFaxImagesSuccess(responses.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(scanFaxImagesFail(error.message));
                    obs.complete();
                });
            });
        })
    );


