import { IState, IAction } from "../../../../shared/interfaces/common";
import { SaleActionTypes } from "../../actions/sales/sale.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> & { showMessage: boolean } = {
  ...ReducerHelper.baseReducer(),
  showMessage: false,
};

export const importSaleReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case SaleActionTypes.IMPORT_SALE: {
      return {
        ...ReducerHelper.baseProcessRequest(state, action),
        showMessage: false,
      };
    }

    case SaleActionTypes.IMPORT_SALE_SUCCESS: {
      return {
        ...ReducerHelper.baseProcessSuccess(state, action),
        showMessage: true,
      };
    }

    case SaleActionTypes.IMPORT_SALE_FAIL: {
      return {
        ...ReducerHelper.baseProcessFailed(state, action),
        data: action.error,
        showMessage: true,
      };
    }

    case SaleActionTypes.IMPORT_SALE_CLOSE_MSG_MODAL: {
      return {
        ...state,
        showMessage: false,
      };
    }

    default: {
      return state;
    }
  }
};
