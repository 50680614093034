import { ofType } from "redux-observable";
import {
  SaleActionTypes,
  getSalesRepresentativesFail,
  getSalesRepresentativesSuccess,
} from "../../actions/sales/sale.actions";
import { IAction } from "../../../../shared/interfaces/common";
import { exhaustMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { GetSalesRepresentativesUseCase } from "../../../../domain/usecases/sales";

export const getSalesRepresentativesEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SaleActionTypes.GET_SALES_REPRESENTATIVES),
    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new GetSalesRepresentativesUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(getSalesRepresentativesSuccess(data));
            obs.complete();
          })
          .catch((error) => {
            obs.next(getSalesRepresentativesFail(error.message));
            obs.complete();
          });
      });
    })
  );
