import { IAction, IState } from "../../../../shared/interfaces/common";
import { CustomerActionTypes } from "../../actions/customers/customer.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> & { showMessage: boolean } = {
  ...ReducerHelper.baseReducer(),
  showMessage: false,
};

export const importHidePriceReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case CustomerActionTypes.IMPORT_HIDE_PRICE: {
      return {
        ...ReducerHelper.baseProcessRequest(state, action),
        showMessage: false,
      };
    }

    case CustomerActionTypes.IMPORT_HIDE_PRICE_SUCCESS: {
      return {
        ...ReducerHelper.baseProcessSuccess(state, action),
        showMessage: true,
      };
    }

    case CustomerActionTypes.IMPORT_HIDE_PRICE_FAIL: {
      return {
        ...ReducerHelper.baseProcessFailed(state, action),
        showMessage: true,
      };
    }

    case CustomerActionTypes.IMPORT_HIDE_PRICE_CLOSE_MSG_MODAL: {
      return {
        ...state,
        showMessage: false,
      };
    }

    default: {
      return state;
    }
  }
};
