import { ofType } from "redux-observable";
import {
  ProductActionTypes,
  getAlarmUploadFileSuccess,
  getAlarmUploadFileFail
} from "../../actions/products/product.actions";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { GetAlarmProductsFileUseCase } from "../../../../domain/usecases/products";

export const getAlarmProductsFileEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new GetAlarmProductsFileUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;
            obs.next(getAlarmUploadFileSuccess(data.data));

            obs.complete();
          })
          .catch((error) => {
            obs.next(getAlarmUploadFileFail(error.message));

            obs.complete();
          });
      });
    })
  );
