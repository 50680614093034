import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportPurchaseOrdersUseCase, ResendOrderConfirmationUseCase, SaveFirebaseTokenUseCase } from "../../../../domain/usecases/order";
import ExportOrdersUseCase from "../../../../domain/usecases/order/ExportOrdersUseCase";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { OrderActionTypes, exportOrdersSuccess, exportOrdersFail, exportPurchaseOrdersSuccess, exportPurchaseOrdersFail, resendOrderConfirmationSuccess, resendOrderConfirmationFail, saveFirebaseTokenSuccess, saveFirebaseTokenFail } from "../../actions/orders/order.actions";


export const saveFirebaseTokenEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.SAVE_FIREBASE_TOKEN),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SaveFirebaseTokenUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(saveFirebaseTokenSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(saveFirebaseTokenFail(error.message));
                    obs.complete();
                });
            });
        })
    );


