import CustomerRepository from "../../../../data/repository/customer";
import ResponseModel from "../../../../models/response";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";

export default class GetHidePriceFileUseCase implements IUseCase {
  private customerRepository: CustomerRepository;

  constructor(public payload: string) {
    this.customerRepository = new CustomerRepository();
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.customerRepository
        .getHidePriceFile(this.payload)
        .then((response: any) => {
          if (response.data.data) {
            resolve(response.data);
          } else {
            reject(ResponseModel.createError(500, response.data.errorMessage));
          }
        })
        .catch(reject);
    });
  };
}
