import { ofType } from "redux-observable";
import { IAction } from "../../../../shared/interfaces/common";
import { exhaustMap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  SaleActionTypes,
  deleteSalesFail,
  deleteSalesSuccess,
} from "../../actions/sales/sale.actions";
import { DeleteSalesUseCase } from "../../../../domain/usecases/sales";

export const deleteSalesEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SaleActionTypes.DELETE_SALES),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new DeleteSalesUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(deleteSalesSuccess(data.data));
            obs.complete();
          })
          .catch((error) => {
            obs.next(deleteSalesFail(error.message));
            obs.complete();
          });
      });
    })
  );
