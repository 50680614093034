import { IUseCase } from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import NotificationRepository from '../../../data/repository/notification';

export default class DeleteTagForCustomerUseCase implements IUseCase {
    private notificationRepository: NotificationRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.notificationRepository = new NotificationRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.notificationRepository.deleteTagForCustomerRepository(this.payload).then((response: any) => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
