import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import ExportOrdersUseCase from "../../../../domain/usecases/order/ExportOrdersUseCase";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, exportOrdersSuccess, exportOrdersFail } from "../../actions/orders/order.actions";


export const exportOrdersEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.EXPORT_ORDERS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportOrdersUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(exportOrdersSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(exportOrdersFail(error.message));
                    obs.complete();
                });
            });
        })
    );


