import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import SearchProductsUseCase from "../../../../domain/usecases/products/SearchProducts";
import { IAction } from "../../../../shared/interfaces/common";
import { ProductActionTypes, searchProductsSuccess, searchProductsFail } from "../../actions/products/product.actions";


export const searchProductsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SEARCH_PRODUCTS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SearchProductsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = ApiHelper.getVariants(responses.data);
                    obs.next(searchProductsSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(searchProductsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


