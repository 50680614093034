import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import DeleteUserUseCase from "../../../../domain/usecases/users/deleteUser";
import { IAction } from "../../../../shared/interfaces/common";
import { UserActionTypes, deleteUserSuccess, deleteUserFail } from "../../actions/users/user.actions";
import { notifySuccess, notifyError } from "../../../../utils/toast";

export const deleteUserEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.DELETE_USER),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new DeleteUserUseCase(payload.id);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('成功');
                    obs.next(deleteUserSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError('Delete User Failed!');
                    obs.next(deleteUserFail(error.message));
                    obs.complete();
                });
            });
        })
    );


