import { combineReducers } from "redux";
import { searchInvoicesReducer } from "./searchInvoicesReducer";
import { createInvoiceReducer } from "./createInvoiceReducer";
import { createInvoiceVersionReducer } from "./createInvoiceVersionReducer";
import { deleteInvoiceReducer } from "./deleteInvoiceReducer";
import { restoreInvoiceReducer } from "./restoreInvoiceReducer";
import { downloadInvoiceReducer } from "./downloadInvoiceReducer";
import { sendInvoiceReminderReducer } from "./sendInvoiceReminderReducer";
import { createBulkUploadJobReducer } from "./createBulkUploadJobReducer";
import { searchDeletedInvoicesReducer } from "./searchDeletedInvoicesReducer";
import { searchDraftInvoicesReducer } from "./searchDraftInvoicesReducer";
import { hasPendingJobReducer } from "./hasPendingJobReducer";
import { publishInvoicesReducer } from "./publishInvoicesReducer";
import { getBulkUploadHistoriesReducer } from "./getBulkUploadHistoriesReducer";
import { switchInvoiceStatusReducer } from "./switchInvoiceStatusReducer";
import { getSignedUrlReducer } from "./getSignedUrlReducer";
import { completeUploadReducer } from "./completeUploadReducer";
import { getInvoiceDetailReducer } from "./getInvoiceDetailReducer";
import { getInvoiceListReducer } from "./getInvoiceListReducer";
import { downloadInvoiceBillReducer } from "./downloadInvoiceBillReducer";
import { filterInvoiceBillReducer } from "./filterInvoiceBillReducer";

export const invoicesReducer = combineReducers({
  searchInvoicesReducer,
  createInvoiceReducer,
  createInvoiceVersionReducer,
  deleteInvoiceReducer,
  restoreInvoiceReducer,
  downloadInvoiceReducer,
  sendInvoiceReminderReducer,
  createBulkUploadJobReducer,
  searchDeletedInvoicesReducer,
  searchDraftInvoicesReducer,
  hasPendingJobReducer,
  publishInvoicesReducer,
  getBulkUploadHistoriesReducer,
  switchInvoiceStatusReducer,
  getSignedUrlReducer,
  completeUploadReducer,
  getInvoiceDetailReducer,
  getInvoiceListReducer,
  downloadInvoiceBillReducer,
  filterInvoiceBillReducer,
});
