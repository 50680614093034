import { ofType } from "redux-observable";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { Observable } from "rxjs";
import {
  SaleActionTypes,
  importSaleFail,
  importSaleSuccess,
} from "../../actions/sales/sale.actions";
import { ImportSaleUseCase } from "../../../../domain/usecases/sales";

export const importSaleEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SaleActionTypes.IMPORT_SALE),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new ImportSaleUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(importSaleSuccess(data.data));
            obs.complete();
          })
          .catch((error: any) => {
            obs.next(importSaleFail(error.data));
            obs.complete();
          });
      });
    })
  );
