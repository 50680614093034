import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import UpdateSakayaInfoUseCase from "../../../../domain/usecases/users/updateSakayaInfo";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { UserActionTypes, updateSakayaInfoSuccess, updateSakayaInfoFail } from "../../actions/users/user.actions";


export const updateSakayaInfoEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.UPDATE_SAKAYA_INFO),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new UpdateSakayaInfoUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    // console.log('adduseredpi===',data);
                    
                    notifySuccess('データを更新しました');
                    obs.next(updateSakayaInfoSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(updateSakayaInfoFail(error.message));
                    obs.complete();
                });
            });
        })
    );


