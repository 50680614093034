import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportPurchaseOrdersUseCase, ResendOrderConfirmationUseCase, SendProductRejectedNotificationUseCase } from "../../../../domain/usecases/order";
import ExportOrdersUseCase from "../../../../domain/usecases/order/ExportOrdersUseCase";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { OrderActionTypes, exportOrdersSuccess, exportOrdersFail, exportPurchaseOrdersSuccess, exportPurchaseOrdersFail, resendOrderConfirmationSuccess, resendOrderConfirmationFail, sendProductRejectedNotificationSuccess, sendProductRejectedNotificationFail } from "../../actions/orders/order.actions";


export const sendProductRejectedNotificationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.SEND_PRODUCTS_REJECTED_NOTIFICATION),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SendProductRejectedNotificationUseCase(payload.orderId);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('メールの送信が完了しました。!');
                    obs.next(sendProductRejectedNotificationSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(sendProductRejectedNotificationFail(error.message));
                    obs.complete();
                });
            });
        })
    );


