import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { GetSakayasInfoSummaryUseCase} from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, getSakayasInfoSummarySuccess, getSakayasInfoSummaryFail } from "../../actions/orders/order.actions";


export const getSakayasInfoSummaryEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.GET_SAKAYAS_INFO_SUMMARY),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetSakayasInfoSummaryUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getSakayasInfoSummarySuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getSakayasInfoSummaryFail(error.message));
                    obs.complete();
                });
            });
        })
    );


