import Type from '../../type';
import {getApiController} from '..';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { GetDeliveryTimePayload, GetImportCustomMyCatalogHistoryPayload, GetImportDeliveryHistoryPayload, IExportPricePayload, IGetProductsPayload, IGetProductsUploadHistoryPayload } from '../../../../../presentation/redux/actions/products/products.types';
import { IActiveCustomersPayload, IExportVariantsPayload, IGetFilePayload, IImportPricesHistoryPayload, ISetColorVariantPayload } from '../../../../../presentation/redux/actions/interfaces/product.types';

const GetSellingPrices = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/Prices?excludeDiscontinuedProduct=${payload.excludeDiscontinuedProduct ?? ''}&sakayaId=${payload.sakayaId}&page=${payload.page}&pageSize=${payload.pageSize}&getSellingProducts=${true}&keyword=${payload.keyword}&showAll=${payload.showAll == null || payload.showAll == undefined ? false : payload.showAll}&priceIds=${payload.priceIds ?? ''}&getFlyerProducts=${payload.getFlyerProducts ?? false}&getSegmentProducts=${payload.getSegmentProducts ?? false}`
});

const GetSellingPricesWithTopCatalog = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/GetSellingPrices?customerId=${payload.customerId}&sakayaId=${payload.sakayaId}&page=${payload.page}&pageSize=${payload.pageSize}&keyword=${payload.keyword}&onlyCatalog=${payload.onlyCatalog}`
});

const GetDiscontinuedPrices = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/Prices?sakayaId=${payload.sakayaId}&page=${payload.page}&pageSize=${payload.pageSize}&getDiscontinuedProducts=${true}&getSellingProducts=${true}&keyword=${payload.keyword}&showAll=${payload.showAll == null || payload.showAll == undefined ? false : payload.showAll}`
});

const SearchProducts = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SearchProducts?page=${payload.page}&pageSize=${payload.pageSize}&keyword=${payload.keyword}`
});

const GetUnSellingPrices = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/Prices?page=${payload.page}&pageSize=${payload.pageSize}&getSellingProducts=${false}&keyword=${payload.keyword}`
});

const GetPausedProducts = (payload : IGetProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/Prices?page=${payload.page}&pageSize=${payload.pageSize}&getSellingProducts=${true}&keyword=${payload.keyword}&getPausedProducts=${true}`
});

const UpdatePrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/UpdatePrice`
});

const SetActivePrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetActivePrice`
});

const SetDiscontinuedPrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetDiscontinuedPrice`
});

const GetActiveCustomers = (payload: IActiveCustomersPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/GetProductActiveCustomers?page=${payload.page}&pageSize=${payload.pageSize}&variantId=${payload.variantId}&sakayaId=${payload.sakayaId}&keyword=${payload.keyword}`
});

const GetOverrideDisplay = (payload: IActiveCustomersPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/GetProductOverrideDisplay?page=${payload.page}&pageSize=${payload.pageSize}&variantId=${payload.variantId}&sakayaId=${payload.sakayaId}&keyword=${payload.keyword}`
});

const UpdatePromotion = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetPromotion`
});

const CreatePrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/CreatePrice`
});

const GetPriceDetail = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/PriceDetail?id=${id}`
});

const GetProductSettings = (payload: any,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/GetProductSettings?keyword=${payload.keyword ?? ''}&customerId=${payload.customerId}&page=${payload.page}&pageSize=${payload.pageSize}&sakayaId=${payload.sakayaId ?? ''}`
});

const GetVariantDetail = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/VariantDetail?variantId=${id}`
});

const DeletePrice = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/DeletePrice?id=${id}`
});

const GetPriceCustomerGroups = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/PriceForGroups?priceId=${id}`
});

const CreateCustomerGroupPrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/CreatePriceForGroup`
});

const SettingCustomProduct = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SettingCustomProduct`
});

const DeleteCustomerGroupPrice = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/DeletePriceForGroup?id=${payload.id ?? ''}&ids=${payload.ids ?? ''}`
});

const UpdateCustomerGroupPrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/UpdatePriceForGroup`
});

const DisablePriceForGroup = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/DisablePriceForGroup`
});

const ExportPrices = (payload: IExportPricePayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ExportPrice?sakayaId=${payload.sakayaId}&exportCustomPrices=${payload.exportCustomPrices}`
});

const ExportPricesV2 = (payload: IExportPricePayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ExportPricesV2?taskId=${payload.taskId ?? ''}&sakayaId=${payload.sakayaId}&exportCustomPrices=${payload.exportCustomPrices}&notOnboardYet=${payload.notOnboardYet ?? false}`
});

const ExportVariants = (payload: IExportVariantsPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ExportVariants?sakayaId=${payload.sakayaId}&variantIds=${payload.variantIds}`
});

const GetFileImportPrice = (payload: IGetFilePayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/GetFileImportPrice?fileName=${encodeURIComponent(payload.fileName)}&fileType=${payload.fileType ?? 0}`
});

const ImportVariants = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportVariants`
});

const ImportPrices = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportPrice`
});

const ImportPricesHistory = (payload: IImportPricesHistoryPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportPricesHistory?page=${payload.page}&type=${payload.type ?? ''}&pageSize=${payload.pageSize}&endDate=${payload.endDate}&startDate=${payload.startDate}&sakayaId=${payload.sakayaId}&id=${payload.id ?? ''}&ignoreLogs=true`
});

const SetProductVisible = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetProductVisible`
});

const SetOverrideDisplay = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetOverrideDisplay`
});

const SetProductPriority = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetProductPriority`
});

const SetColorVariant = (payload: ISetColorVariantPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/SetColorVariant?variantId=${payload.variantId}&color=${payload.color}`
});

const ImportAlarmProducts = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportAlarmPurchasedProduct`
});

const GetUploadProducts = (payload: IGetProductsUploadHistoryPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportAlarmPurchasedHistory?Page=${payload.page}&PageSize=${payload.pageSize}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&ignoreLogs=true`
});

const GetDetailError = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/GetDetailError?id=${payload.id}&category=${payload.category}`
});

const GetAlarmUploadProductsFile = (payload: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/GetFileImportPrice?fileName=${encodeURIComponent(payload)}`
});

const GetDeliveryTime = ({ page, pageSize, sakayaId, keyword }: GetDeliveryTimePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/deliverytime/v1.0/SearchDeliveryTime?&page=${page}&pageSize=${pageSize}&sakayaId=${sakayaId}${keyword ? '&Keyword='+keyword : ''}`
});

const GetImportDeliveryHistory = ({ page, pageSize, startDate, endDate, sakayaId }: GetImportDeliveryHistoryPayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/deliverytime/v1.0/SearchImportDeliveryHistory?&ignoreLogs=true`;
    if (page) Path += `&page=${page}`;
    if (pageSize) Path += `&pageSize=${pageSize}`;
    if (startDate) Path += `&startDate=${startDate}`;
    if (endDate) Path += `&endDate=${endDate}`;
    if (sakayaId) Path += `&sakayaId=${sakayaId}`;

    return {
        Type: Type.Customer,
        Path,
    };
};

const ImportDeliveryTime = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/deliverytime/v1.0/ImportDataFromCSV`,
});

const ImportCustomMyCatalog = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/ImportCSVCustomMyCatalog`,
});

const GetImportCustomMyCatalogHistory = ({ customerId, page, pageSize, startDate, endDate, sakayaId }: GetImportCustomMyCatalogHistoryPayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/product/v1.0/SearchImportCustomMyCatalogHistory?`;
    if (customerId) Path += `&customerId=${customerId}`;
    if (page) Path += `&page=${page}`;
    if (pageSize) Path += `&pageSize=${pageSize}`;
    if (startDate) Path += `&startDate=${startDate}`;
    if (endDate) Path += `&endDate=${endDate}`;
    if (sakayaId) Path += `&sakayaId=${sakayaId}`;

    return {
        Type: Type.Customer,
        Path,
    };
};

export default {
    GetSellingPricesWithTopCatalog,
    GetSellingPrices,
    GetDiscontinuedPrices,
    SearchProducts,
    GetVariantDetail,
    GetUnSellingPrices,
    GetPausedProducts,
    UpdatePrice,
    SetActivePrice,
    SetDiscontinuedPrice,
    GetActiveCustomers,
    GetOverrideDisplay,
    ExportPrices,
    ExportPricesV2,
    ExportVariants,
    GetFileImportPrice,
    ImportVariants,
    ImportPrices,
    ImportPricesHistory,
    UpdatePromotion,
    CreatePrice,
    CreateCustomerGroupPrice,
    SettingCustomProduct,
    UpdateCustomerGroupPrice,
    DisablePriceForGroup,
    DeleteCustomerGroupPrice,
    GetPriceDetail,
    GetProductSettings,
    DeletePrice,
    GetPriceCustomerGroups,
    SetProductVisible,
    SetOverrideDisplay,
    SetProductPriority,
    SetColorVariant,
    ImportAlarmProducts,
    GetUploadProducts,
    GetAlarmUploadProductsFile,
    GetDeliveryTime,
    GetImportDeliveryHistory,
    ImportDeliveryTime,
    ImportCustomMyCatalog,
    GetImportCustomMyCatalogHistory,
    GetDetailError
};

