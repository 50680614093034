import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { getFileImportPriceFail, getFileImportPriceSuccess, ProductActionTypes } from "../../actions/products/product.actions";
import { GetFileImportPriceUseCase } from "../../../../domain/usecases/products";

export const getFileImportPriceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.GET_FILE_AWS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetFileImportPriceUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getFileImportPriceSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getFileImportPriceFail(error.message));
                    obs.complete();
                });
            });
        })
    );


