import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { DisablePriceForGroupUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, disablePriceForGroupSuccess, disablePriceForGroupFail, getPriceCustomerGroupsSuccess } from "../../actions/products/product.actions";


export const disablePriceForGroupEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.DISABLE_CUSTOMER_GROUP_PRICE),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new DisablePriceForGroupUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    const customerGroupPriceData = state$.value.productsReducer.getPriceCustomerGroupsReducer.data;
                    if (customerGroupPriceData) {
                        const newCustomerGroupPriceData = customerGroupPriceData.map((item: any) => {
                            if (item.id == payload.id) {
                                return {
                                    ...item,
                                    defaultPrice: payload.defaultPrice,
                                    salePrice: payload.salePrice

                                }
                            } else {
                                return {
                                    ...item
                                }
                            }
                        });
                        obs.next(getPriceCustomerGroupsSuccess(newCustomerGroupPriceData));
                    }
                    notifySuccess('特別価格を更新しました！');
                    obs.next(disablePriceForGroupSuccess(data));
                    obs.complete();
                }).catch(error => {
                    if (error.message) {
                        notifyError(error.message);
                    } else {
                        notifyError("Price Group Update Failed !");
                    }
                    obs.next(disablePriceForGroupFail(error.message));
                    obs.complete();
                });
            });
        })
    );
