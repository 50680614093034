import CustomerRepository from "../../../../data/repository/customer";
import { IGetCustomerHidePriceUploadHistoryPayload } from "../../../../presentation/redux/actions/interfaces/customers.types";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";
import ValidationResult from "../../../../models/validationResult";
import ResponseModel from "../../../../models/response";

export default class GetCustomerHidePriceUploadHistoryUseCase
  implements IUseCase
{
  private customerRepository: CustomerRepository;

  constructor(public payload: IGetCustomerHidePriceUploadHistoryPayload) {
    this.customerRepository = new CustomerRepository();
  }

  validate = (): ValidationResult => {
    return new ValidationResult(true);
  };

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      const validationResult = this.validate();

      if (validationResult.isValid) {
        this.customerRepository
          .getCustomerHidePriceUploadHistory(this.payload)
          .then((response: any) => {
            if (response.data.data) {
              resolve(response.data);
            } else {
              reject(
                ResponseModel.createError(500, response.data.errorMessage)
              );
            }
          })
          .catch(reject);
      }
    });
  };
}
