import BackendResource from '../../../gateway/api/resource/orderonbehalf';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import {SitecoreResource} from '../../../gateway/api/resource';
import 
{
   IGetDraftCartsRespone, 
   IGetDraftCartsRequest ,
   ICreateOnBehalfOrderResponse,
   ICreateOnBehalfOrderRequest,
   ISaveCartRequest,
   ISaveCartResponse,
   IClearCartRequest,
   IClearCartResponse,
   IAddNewCartRequest,
   IAddNewCartResponse,
   IGetCartRequest,
   IGetCartResponse
 } from '../../../../presentation/redux/actions/interfaces/orderOnBehalf.type';
 import { APIResponse } from '../../../../models/response/types';

const getDraftCarts = (payload: IGetDraftCartsRequest): Promise<ResponseModel<IGetDraftCartsRespone>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = SitecoreResource.OrderOnBehalf.GetDraftCarts(payload);   
     return apiGateway.doGetRequest(request);
};

const createOnBehalfOrder = (payload: ICreateOnBehalfOrderRequest): Promise<ResponseModel<ICreateOnBehalfOrderResponse>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = BackendResource.CreateOnBehalfOrder();   
     return apiGateway.doPostRequest(request,payload);
};

const addNewCart = (payload: IAddNewCartRequest): Promise<ResponseModel<IAddNewCartResponse>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = BackendResource.AddNewCart();   
     return apiGateway.doPostRequest(request,payload);
};

const saveCart = (payload: ISaveCartRequest): Promise<ResponseModel<ISaveCartResponse>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = BackendResource.SaveCart();   
    return apiGateway.doPutRequest(request,payload);
};

const clearCart = (payload: IClearCartRequest): Promise<ResponseModel<IClearCartResponse>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = BackendResource.ClearCart(payload);   
    return apiGateway.doDeleteRequest(request);
};
const getCart = (payload: IGetCartRequest): Promise<ResponseModel<IGetCartResponse>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const request = BackendResource.GetCart(payload);   
    return apiGateway.doGetRequest(request);
};


export default {
    getDraftCarts,
    addNewCart,
    clearCart,
    saveCart,
    getCart,
    createOnBehalfOrder
};
