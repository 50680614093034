import {gql} from 'graphql-request';

export const getNotAssignStoreCategoriesQuery = () => {
    return gql`
    query {
      result:storeCategories{
        id
        name
      }
    }`;
  }

 

  