import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from "../rootEpics"
import { rootReducer } from "../rootReducers"
import logger from 'redux-logger';
import { configureStore } from "@reduxjs/toolkit";

const epicMiddleware = createEpicMiddleware();

const store = process.env.NODE_ENV === 'development' ? configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      epicMiddleware,
      logger
    ),
}) : configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      epicMiddleware
    ),
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export default store;

