import GraphqlGateway from '../../../gateway/graphql';
import { getOrdersQuery } from '../../../gateway/graphql/queries/orders';
import { getDeliverySlotsQuery } from '../../../gateway/graphql/queries/orders/getDeliverySlots';
import {GraphCMSResource} from '../../../gateway/graphql/resource';
import { graphConfig } from '../../../setting/graphConfig';

const getOrderData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const ordersResource = GraphCMSResource.Order.GetOrders();
    const query = getOrdersQuery(ordersResource.Path);
    return graphqlGateway.doGraphqlRequest(query, ordersResource);
};

const getDeliverySlotsData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const ordersResource = GraphCMSResource.Order.GetOrders();
    const query = getDeliverySlotsQuery();
    return graphqlGateway.doGraphqlRequest(query, ordersResource);
};

export default {
    getOrderData,
    getDeliverySlotsData
};

