import { TokenType } from "../../../../data/constants";
import { IAction, IState } from "../../../../shared/interfaces/common";
import { UserActionTypes } from "../../actions/users/user.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const updatePasswordReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case UserActionTypes.USER_UPDATE_PASSWORD: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case UserActionTypes.USER_UPDATE_PASSWORD_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case UserActionTypes.USER_UPDATE_PASSWORD_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
