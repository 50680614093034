import { IAction } from "../../../../shared/interfaces/common";
import {
  IAddSaleRepresentativePayload,
  IDeleteSalesPayload,
  IGetSaleRepresentativePayload,
  IUpdateSalePayload,
  SaleRepresentativeType,
} from "../interfaces/saleInfo.types";

export enum SaleActionTypes {
  GET_SALES_REPRESENTATIVES = "[Sales] GET_SALES_REPRESENTATIVES",
  GET_SALES_REPRESENTATIVES_SUCCESS = "[Sales] GET_SALES_REPRESENTATIVES_SUCCESS",
  GET_SALES_REPRESENTATIVES_FAIL = "[Sales] GET_SALES_REPRESENTATIVES_FAIL",

  ADD_SALES_REPRESENTATIVES = "[Sales] ADD_SALES_REPRESENTATIVES",
  ADD_SALES_REPRESENTATIVES_SUCCESS = "[Sales] ADD_SALES_REPRESENTATIVES_SUCCESS",
  ADD_SALES_REPRESENTATIVES_FAIL = "[Sales] ADD_SALES_REPRESENTATIVES_FAIL",
  ON_ADD_SALES_SUCCESS = "[Sales] ON_ADD_SALES_SUCCESS",

  IMPORT_SALE = "[Sales] IMPORT_SALE",
  IMPORT_SALE_SUCCESS = "[Sales] IMPORT_SALE_SUCCESS",
  IMPORT_SALE_FAIL = "[Sales] IMPORT_SALE_FAIL",
  IMPORT_SALE_CLOSE_MSG_MODAL = "[Sales] IMPORT_SALE_CLOSE_MSG_MODAL",

  DELETE_SALES = "[Sales] DELETE_SALES",
  DELETE_SALES_SUCCESS = "[Sales] DELETE_SALES_SUCCESS",
  DELETE_SALES_FAIL = "[Sales] DELETE_SALES_FAIL",

  UPDATE_SALE = "[Sales] UPDATE_SALE",
  UPDATE_SALE_SUCCESS = "[Sales] UPDATE_SALE_SUCCESS",
  UPDATE_SALE_FAIL = "[Sales] UPDATE_SALE_FAIL",
  RESET_UPDATE_STATE = "[Sales] RESET_UPDATE_STATE",
  ON_UPDATE_SALE_SUCCESS = "[Sales] ON_UPDATE_SALE_SUCCESS",
}

export const getSalesRepresentatives = (
  payload: IGetSaleRepresentativePayload
): IAction<any> => ({
  type: SaleActionTypes.GET_SALES_REPRESENTATIVES,
  payload,
});

export const getSalesRepresentativesSuccess = (payload: any): IAction<any> => ({
  type: SaleActionTypes.GET_SALES_REPRESENTATIVES_SUCCESS,
  payload,
});

export const getSalesRepresentativesFail = (error: any): IAction<any> => ({
  type: SaleActionTypes.GET_SALES_REPRESENTATIVES_FAIL,
  error,
});

export const addSaleRepresentative = (
  payload: IAddSaleRepresentativePayload
): IAction<any> => ({
  type: SaleActionTypes.ADD_SALES_REPRESENTATIVES,
  payload,
});

export const addSaleRepresentativeSuccess = (payload: any): IAction<any> => ({
  type: SaleActionTypes.ADD_SALES_REPRESENTATIVES_SUCCESS,
  payload,
});

export const addSaleRepresentativeFail = (error: any): IAction<any> => ({
  type: SaleActionTypes.ADD_SALES_REPRESENTATIVES_FAIL,
  error,
});

export const onAddSaleSuccess = (payload: {
  data: SaleRepresentativeType;
}): IAction<any> => ({
  type: SaleActionTypes.ON_ADD_SALES_SUCCESS,
  payload,
});

export const importSale = (payload: any): IAction<any> => ({
  type: SaleActionTypes.IMPORT_SALE,
  payload,
});

export const importSaleSuccess = (payload?: any): IAction<any> => ({
  type: SaleActionTypes.IMPORT_SALE_SUCCESS,
  payload,
});

export const importSaleFail = (error: any): IAction<any> => ({
  type: SaleActionTypes.IMPORT_SALE_FAIL,
  error,
});

export const importSale_CloseMsgModal = (): IAction<any> => ({
  type: SaleActionTypes.IMPORT_SALE_CLOSE_MSG_MODAL,
});

export const deleteSales = (payload: IDeleteSalesPayload): IAction<any> => ({
  type: SaleActionTypes.DELETE_SALES,
  payload,
});

export const deleteSalesSuccess = (payload: any): IAction<any> => ({
  type: SaleActionTypes.DELETE_SALES_SUCCESS,
  payload,
});

export const deleteSalesFail = (error: any): IAction<any> => ({
  type: SaleActionTypes.DELETE_SALES_FAIL,
  error,
});

export const updateSale = (payload: IUpdateSalePayload): IAction<any> => ({
  type: SaleActionTypes.UPDATE_SALE,
  payload,
});

export const updateSaleSuccess = (payload: any): IAction<any> => ({
  type: SaleActionTypes.UPDATE_SALE_SUCCESS,
  payload,
});

export const updateSaleFail = (error: any): IAction<any> => ({
  type: SaleActionTypes.UPDATE_SALE_FAIL,
  error,
});

export const resetUpdateState = (): IAction<any> => ({
  type: SaleActionTypes.RESET_UPDATE_STATE,
});

export const onUpdateSaleSuccess = (
  payload: SaleRepresentativeType
): IAction<any> => ({
  type: SaleActionTypes.ON_UPDATE_SALE_SUCCESS,
  payload,
});
