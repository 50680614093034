import { IState, IAction } from "../../../../shared/interfaces/common";
import { InvoiceActionTypes } from "../../actions/invoices/invoices.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<any> = ReducerHelper.baseReducer();

export const filterInvoiceBillReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case InvoiceActionTypes.FILTER_INVOICE_BILL: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};
