import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { exportPricesFail, exportPricesSuccess, exportVariantsFail, exportVariantsSuccess, importVariantsFail, importVariantsSuccess, ProductActionTypes } from "../../actions/products/product.actions";
import { ExportPricesUseCase, ExportVariantsUseCase, ImportVariantsUseCase } from "../../../../domain/usecases/products";
import { notifySuccess } from "../../../../utils/toast";

export const importVariantsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.IMPORT_VARIANTS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ImportVariantsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('ファイルの取り込みが完了しました！結果をメールで通知いたします。')
                    obs.next(importVariantsSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(importVariantsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


