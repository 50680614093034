import GraphqlGateway from '../../../gateway/graphql';
import { getNotAssignPrefecturesQuery } from '../../../gateway/graphql/queries/orders/getPrefectures';
import { getNotAssignReasonsQuery } from '../../../gateway/graphql/queries/orders/getReasons';
import { getNotAssignStockStatusQuery } from '../../../gateway/graphql/queries/orders/getStockStatus';
import { getNotAssignStoreCategoriesQuery } from '../../../gateway/graphql/queries/orders/getStoreCategories';
import { graphConfig } from '../../../setting/graphConfig';

const getNotAssignPrefecturesData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const query = getNotAssignPrefecturesQuery();
    return graphqlGateway.doGraphqlRequest(query, {Type: '', Path: ''});
};

const getNotAssignStockStatusData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const query = getNotAssignStockStatusQuery();
    return graphqlGateway.doGraphqlRequest(query, {Type: '', Path: ''});
};

const getNotAssignStoreCategoriesData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const query = getNotAssignStoreCategoriesQuery();
    return graphqlGateway.doGraphqlRequest(query, {Type: '', Path: ''});
};

const getNotAssignReasonsData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const query = getNotAssignReasonsQuery();
    return graphqlGateway.doGraphqlRequest(query, {Type: '', Path: ''});
};

export default {
    getNotAssignPrefecturesData,
    getNotAssignStockStatusData,
    getNotAssignStoreCategoriesData,
    getNotAssignReasonsData
};

