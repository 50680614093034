import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportSakayasInfoSummaryUseCase } from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { OrderActionTypes, exportSakayasInfoSummarySuccess, exportSakayasInfoSummaryFail } from "../../actions/orders/order.actions";


export const exportSakayasInfoSummaryEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.EXPORT_SAKAYA_INFO_SUMMARY),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportSakayasInfoSummaryUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('エクスポート成功!');
                    obs.next(exportSakayasInfoSummarySuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(exportSakayasInfoSummaryFail(error.message));
                    obs.complete();
                });
            });
        })
    );


