import {gql} from 'graphql-request';

export const getDeliverySlotsQuery = () => {
    return gql`
    query {
      result:labels(where: {category_contains:"DeliverySlot"}, first: 1000){
        id
        name
      }
    }`;
  }

 

  