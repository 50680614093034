import Cookies from 'js-cookie';

export function identifyMixpanel(user) {
  if (window.mixpanel === undefined) {
    return;
  }
  try
  {
  let id = window.mixpanel.get_distinct_id();
  if (id.includes('$device:')) {
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set({
      Username: `${user.email}`,
      $name: `${user.first_name} ${user.last_name}`,
      $email: user.email,
      id: user.id,
    });
  }
  }catch{}
}

export function triggerLogout(user) {
  if(window.mixpanel === undefined) {
    return;
  }
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name
  }
  props.path =document.location.href;

  window.mixpanel.track('SignOut', props);
  window.mixpanel.reset()
  }catch{}
}

export function triggerPageView() {
  if (window.mixpanel === undefined) {
    return;
  }
  try
  {
  window.mixpanel.track('PageView', {
    path: document.location.href,
    reference: document.referrer,
    sakayaId: JSON.parse(localStorage.getItem('user_info'))?.sakaya?.id || null,
    sakayaName: JSON.parse(localStorage.getItem('user_info'))?.sakaya?.name || null
  });
  }catch{}
}

export function triggerEvent(eventName, props) {
  if(window.mixpanel === undefined){
    return;
  }
  try
  {
  window.mixpanel.track(eventName, props);
  }catch{}
}

export function trigger404(user) {
  try
  {
  let props = {
    path: document.location.href,
    reference: document.referrer,
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name
  };

  triggerEvent('404', props);
  }catch{}
}

export function triggerSignInSuccess(user){
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name
  }

  triggerEvent('SignInSuccess', props);
  }catch{}
}

export function triggerSignInFail(user, error){
  try
  {
  const props = {
    email: user.email,
    error: error
  }

  triggerEvent('SignInFail', props);
  }catch{}
}

export function triggerEditProfile(user) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name
  }

  triggerEvent('EditProfile', props);
  }catch{}
}

export function triggerUpdatePassword(user) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name
  }

  triggerEvent('UpdatePassword', props);
  }catch{}
}

export function triggerFilterDashBoard(user, filter) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    status: filter.status,
    horecaName: filter.horecaName,
    startDate: filter.startDate,
    endDate: filter.endDate,
  }

  triggerEvent('FilterDashBoard', props);
  }catch{}
}

export function triggerProductsEvents(eventName, user, options) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    ...options
  }

  triggerEvent(eventName, props);
  }catch{}
}

export function triggerSearchCustomers(user, filter){
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    keyword: filter.keyword
  }

  triggerEvent('SearchCustomers', props);
  }catch{}
}

export function triggerSetActiveCustomer(user, customer, active) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    customerEmail: customer.email,
    active: active
  }

  triggerEvent('SetActiveCustomer', props);
  }catch{}
}
export function triggerSetHidePrice(user, customer, hidePrice) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    customerEmail: customer.email,
    hidePrice: hidePrice
  }

  triggerEvent('SetHidePrice', props);
  }catch{}
}

export function triggerImportHidePrice(user, fileName){
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    fileName: fileName
  }

  triggerEvent('ImportHidePrice', props);
  }catch{}
}

export function triggerNotificationEvents (eventName, user, options) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    ...options
  }

  triggerEvent(eventName, props);
  }catch{}
}

export function triggerFaxEvents (eventName, user, faxInfo) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    faxName: faxInfo.faxName,
    faxId: faxInfo.id
  }

  triggerEvent(eventName, props);
  }catch{}
}

export function triggerSearchOrders (user, filter) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    area: filter.area,
    status: filter.status,
    dateType: filter.dateType,
    startDate: filter.startDate,
    endDate: filter.endDate
  }

  triggerEvent('SearchOrders', props);
  }catch{}
}

export function triggerDownloadOrders(user, payload){
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    ...payload
  }
  triggerEvent('DownloadOrders', props);
  }catch{}
}

export function triggerResendOrderConfirmation(user, orderId) {
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    orderId: orderId
  }
  triggerEvent('ResendOrderConfirmation', props);
  }catch{}
}

export function triggerUpdateSakaya(user, options){
  try
  {
  const props = {
    userId: user.id,
    email: user.email,
    sakayaId: user.sakaya?.id,
    sakayaName: user.sakaya?.name,
    ...options
  }

  triggerEvent('UpdateSakaya', props);
  }catch{}
}