import CustomerRepository from "../../../../data/repository/customer";
import ResponseModel from "../../../../models/response";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";
import { notifyError, notifySuccess } from "../../../../utils/toast";

export default class ImportHidePriceUseCase implements IUseCase {
  private customerRepository: CustomerRepository;

  constructor(public payload: any) {
    this.customerRepository = new CustomerRepository();
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.customerRepository
        .importHidePrice(this.payload)
        .then((response: any) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(ResponseModel.createError(500, response.data.errorMessage));

            // notifyError(
            //   "価格のインポートに失敗しました。有効なデータを含むワークシートがありませんでした。"
            // );
          }
        })
        .catch(reject);
    });
  };
}
