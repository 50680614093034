// function TranslatePagination(){
//     let paginations = document.querySelectorAll('.paginationContainer .uppercase');
//     paginations.forEach(element => {
//         if(element.innerHTML.indexOf('Showing') == 0){
//             element.classList.add('dpn');
//             let pageNumber = element.innerHTML.substring(8, element.innerHTML.indexOf('of')-1);
//             let pageCount = element.innerHTML.substring(element.innerHTML.lastIndexOf(' ')+1);
//             if(element.nextElementSibling.classList.contains('japan-page')){
//                 element.nextElementSibling.innerHTML = pageCount + '件中/ ' + pageNumber + '件を表示';
//             }
//             else{
//                 let newElement = document.createElement('span');
//                 newElement.className = 'japan-page flex items-center font-semibold tracking-wide uppercase';
//                 newElement.innerHTML = pageCount + '件中/ ' + pageNumber + '件を表示';
//                 element.parentNode.insertBefore(newElement, element.nextElementSibling);
//             }
//         }
//     });
//     setTimeout(() => {
//         TranslatePagination();
//     }, 1000);
// }

// TranslatePagination();
function HandleLabelClick(){
    document.body.addEventListener( 'click', function ( event ) {
        if( event.target.classList.contains('dynamic-placeholder')) {
            event.srcElement.previousElementSibling.focus();
        };
      } );
}
HandleLabelClick();