import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { importAlarmProductsSuccess, importAlarmProductsFail, importPricesFail, importPricesSuccess, ProductActionTypes } from "../../actions/products/product.actions";
import { ImportPricesCase, ImportAlarmProductsUseCase } from "../../../../domain/usecases/products";

export const importPricesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.IMPORT_PRICES),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ImportPricesCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(importPricesSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(importPricesFail(error.message));
                    obs.complete();
                });
            });
        })
    );




export const importAlarmProductsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ProductActionTypes.IMPORT_ALARM_PRODUCTS),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new ImportAlarmProductsUseCase(payload);

        useCase
          .execute()
          .then((responses: any) => {
            const data = responses;

            obs.next(importAlarmProductsSuccess(data.data));

            obs.complete();
          })
          .catch((error) => {
            console.log('FAILED', error)
            obs.next(importAlarmProductsFail(error));

            obs.complete();
          });
      });
    })
  );
