import { IAction } from "../../../../shared/interfaces/common";
import { IChangeOrderStatusPayload, IGetOrderPayload, IGetOrdersPayload, IExportOrdersPayload, IUpdateOrderPayload, IUpdateOrderProuctsPayload, IExportPurchaseOrdersPayload, IGetRevenueInformationPayload, IGenerateProductsPdf, IExportPosOrdersPayload, IChangeOrderReturnStatusPayload } from "../interfaces/order.types";

// Order Action Types
export enum OrderActionTypes {
  GET_ORDERS = "[Orders] GET_ORDERS",
  GET_ORDERS_SUCCESS = "[Orders] GET_ORDERS_SUCCESS",
  GET_ORDERS_FAIL = "[Orders] GET_ORDERS_FAIL",

  GET_REVENUEINFORMATION = "[Orders] GET_REVENUEINFORMATION",
  GET_REVENUEINFORMATION_SUCCESS = "[Orders] GET_REVENUEINFORMATION_SUCCESS",
  GET_REVENUEINFORMATION_FAIL = "[Orders] GET_REVENUEINFORMATION_FAIL",

  GET_ORDER = "[Orders] GET_ORDER",
  GET_ORDER_SUCCESS = "[Orders] GET_ORDER_SUCCESS",
  GET_ORDER_FAIL = "[Orders] GET_ORDER_FAIL",

  CHANGE_ORDER_STATUS = "[Orders] CHANGE_ORDER_STATUS",
  CHANGE_ORDER_STATUS_SUCCESS = "[Orders] CHANGE_ORDER_STATUS_SUCCESS",
  CHANGE_ORDER_STATUS_FAIL = "[Orders] CHANGE_ORDER_STATUS_FAIL",

  CHANGE_ORDER_RETURN_STATUS = "[Orders] CHANGE_ORDER_RETURN_STATUS",
  CHANGE_ORDER_RETURN_STATUS_SUCCESS = "[Orders] CHANGE_ORDER_RETURN_STATUS_SUCCESS",
  CHANGE_ORDER_RETURN_STATUS_FAIL = "[Orders] CHANGE_ORDER_RETURN_STATUS_FAIL",

  EXPORT_ORDERS_HISTORIES = "[Orders] EXPORT_ORDERS_HISTORIES",
  EXPORT_ORDERS_HISTORIES_SUCCESS = "[Orders] EXPORT_ORDERS_HISTORIES_SUCCESS",
  EXPORT_ORDERS_HISTORIES_FAIL = "[Orders] EXPORT_ORDERS_HISTORIES_FAIL",

  EXPORT_ORDERS_V2 = "[Orders] EXPORT_ORDERS_V2",
  EXPORT_ORDERS_V2_SUCCESS = "[Orders] EXPORT_ORDERS_V2_SUCCESS",
  EXPORT_ORDERS_V2_FAIL = "[Orders] EXPORT_ORDERS_V2_FAIL",

  CHANGE_ORDER_PRODUCT_STATUS = "[Orders] CHANGE_ORDER_PRODUCT_STATUS",
  CHANGE_ORDER_PRODUCT_STATUS_SUCCESS = "[Orders] CHANGE_ORDER_PRODUCT_STATUS_SUCCESS",
  CHANGE_ORDER_PRODUCT_STATUS_FAIL = "[Orders] CHANGE_ORDER_PRODUCT_STATUS_FAIL",

  GET_ORDERS_BY_CUSTOMER = "[Orders] GET_ORDERS_BY_CUSTOMER",
  GET_ORDERS_BY_CUSTOMER_SUCCESS = "[Orders] GET_ORDERS_BY_CUSTOMER_SUCCESS",
  GET_ORDERS_BY_CUSTOMER_FAIL = "[Orders] GET_ORDERS_BY_CUSTOMER_FAIL",

  EXPORT_ORDERS = "[Orders] EXPORT_ORDERS",
  EXPORT_ORDERS_SUCCESS = "[Orders] EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAIL = "[Orders] EXPORT_ORDERS_FAIL",

  EXPORT_SALES = "[Orders] EXPORT_SALES",
  EXPORT_SALES_SUCCESS = "[Orders] EXPORT_SALES_SUCCESS",
  EXPORT_SALES_FAIL = "[Orders] EXPORT_SALES_FAIL",

  UPDATE_ORDER = "[Orders] UPDATE_ORDER",
  UPDATE_ORDER_SUCCESS = "[Orders] UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAIL = "[Orders] UPDATE_ORDER_FAIL",

  UPDATE_ORDER_PRODUCTS = "[Orders] UPDATE_ORDER_PRODUCTS",
  UPDATE_ORDER_PRODUCTS_SUCCESS = "[Orders] UPDATE_ORDER_PRODUCTS_SUCCESS",
  UPDATE_ORDER_PRODUCTS_FAIL = "[Orders] UPDATE_ORDER_PRODUCTS_FAIL",

  EXPORT_PURCHASE_ORDERS = "[Orders] EXPORT_PURCHASE_ORDERS",
  EXPORT_PURCHASE_ORDERS_SUCCESS = "[Orders] EXPORT_PURCHASE_ORDERS_SUCCESS",
  EXPORT_PURCHASE_ORDERS_FAIL = "[Orders] EXPORT_PURCHASE_ORDERS_FAIL",

  EXPORT_SAKAYA_INFO_SUMMARY = "[Orders] EXPORT_SAKAYA_INFO_SUMMARY",
  EXPORT_SAKAYA_INFO_SUMMARY_SUCCESS = "[Orders] EXPORT_SAKAYA_INFO_SUMMARY_SUCCESS",
  EXPORT_SAKAYA_INFO_SUMMARY_FAIL = "[Orders] EXPORT_SAKAYA_INFO_SUMMARY_FAIL",

  RESEND_ORDER_CONFIRMATION = "[Orders] RESEND_ORDER_CONFIRMATION_ORDERS",
  RESEND_ORDER_CONFIRMATION_SUCCESS = "[Orders] RESEND_ORDER_CONFIRMATION_SUCCESS",
  RESEND_ORDER_CONFIRMATION_FAIL = "[Orders] RESEND_ORDER_CONFIRMATION_FAIL",

  GET_PURCHASED_PRODUCTS = "[Orders] GET_PURCHASED_PRODUCTS",
  GET_PURCHASED_PRODUCTS_SUCCESS = "[Orders] GET_PURCHASED_PRODUCTS_SUCCESS",
  GET_PURCHASED_PRODUCTS_FAIL = "[Orders] GET_PURCHASED_PRODUCTS_FAIL",

  SEND_PRODUCTS_REJECTED_NOTIFICATION = "[Orders] SEND_PRODUCTS_REJECTED_NOTIFICATION",
  SEND_PRODUCTS_REJECTED_NOTIFICATION_SUCCESS = "[Orders] SEND_PRODUCTS_REJECTED_NOTIFICATION_SUCCESS",
  SEND_PRODUCTS_REJECTED_NOTIFICATION_FAIL = "[Orders] SEND_PRODUCTS_REJECTED_NOTIFICATION_FAIL",

  SAVE_FIREBASE_TOKEN = "[Orders] SAVE_FIREBASE_TOKEN_ORDERS",
  SAVE_FIREBASE_TOKEN_SUCCESS = "[Orders] SAVE_FIREBASE_TOKEN_SUCCESS",
  SAVE_FIREBASE_TOKEN_FAIL = "[Orders] SAVE_FIREBASE_TOKEN_FAIL",

  GET_ORDER_SUMMARY = "[Orders] GET_ORDER_SUMMARY",
  GET_ORDER_SUMMARY_SUCCESS = "[Orders] GET_ORDER_SUMMARY_SUCCESS",
  GET_ORDER_SUMMARY_FAIL = "[Orders] GET_ORDER_SUMMARY_FAIL",

  GET_SAKAYAS_INFO_SUMMARY = "[Orders] GET_SAKAYAS_INFO_SUMMARY",
  GET_SAKAYAS_INFO_SUMMARY_SUCCESS = "[Orders] GET_SAKAYAS_INFO_SUCCESS",
  GET_SAKAYAS_INFO_SUMMARY_FAIL = "[Orders] GET_SAKAYAS_INFO_FAIL",

  GENERATE_PRODUCTS_SELECTED_PDF = "[OCR] GENERATE_PRODUCTS_SELECTED_PDF",
  GENERATE_PRODUCTS_SELECTED_PDF_SUCCESS = "[OCR] GENERATE_PRODUCTS_SELECTED_PDF_SUCCESS",
  GENERATE_PRODUCTS_SELECTED_PDF_FAIL = "[OCR] GENERATE_PRODUCTS_SELECTED_PDF_FAIL",

  CREATE_SELECTED_OPTIONS = "[OCR] CREATE_SELECTED_OPTIONS",
  CREATE_SELECTED_OPTIONS_SUCCESS = "[OCR] CREATE_SELECTED_OPTIONS_SUCCESS",
  CREATE_SELECTED_OPTIONS_FAIL = "[OCR] CREATE_SELECTED_OPTIONS_FAIL",

  DELETE_SELECTED_OPTIONS = "[OCR] DELETE_SELECTED_OPTIONS",
  DELETE_SELECTED_OPTIONS_SUCCESS = "[OCR] DELETE_SELECTED_OPTIONS_SUCCESS",
  DELETE_SELECTED_OPTIONS_FAIL = "[OCR] DELETE_SELECTED_OPTIONS_FAIL",

  GET_PRODUCTS_SELECTED = "[OCR] GET_PRODUCTS_SELECTED",
  GET_PRODUCTS_SELECTED_SUCCESS = "[OCR] GET_PRODUCTS_SELECTED_SUCCESS",
  GET_PRODUCTS_SELECTED_FAIL = "[OCR] GET_PRODUCTS_SELECTED_PDF_FAIL",

  GET_FAX_FILES = "[OCR] GET_FAX_FILES",
  GET_FAX_FILES_SUCCESS = "[OCR] GET_FAX_FILES_SUCCESS",
  GET_FAX_FILES_FAIL = "[OCR] GET_FAX_FILES_FAIL",

  SCAN_FAX_IMAGES = "[OCR] SCAN_FAX_IMAGES",
  SCAN_FAX_IMAGES_SUCCESS = "[OCR] SCAN_FAX_IMAGES_SUCCESS",
  SCAN_FAX_IMAGES_FAIL = "[OCR] SCAN_FAX_IMAGES_FAIL",

  CREATE_ORDER = "[OCR] CREATE_ORDER",
  CREATE_ORDER_SUCCESS = "[OCR] CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAIL = "[OCR] CREATE_ORDER_FAIL",

  GET_FAX_CONTENT = "[OCR] GET_FAX_CONTENT",
  GET_FAX_CONTENT_SUCCESS = "[OCR] GET_FAX_CONTENT_SUCCESS",
  GET_FAX_CONTENT_FAIL = "[OCR] GET_FAX_CONTENT_FAIL",

  CANCEL_FAX = "[OCR] CANCEL_FAX",
  CANCEL_FAX_SUCCESS = "[OCR] CANCEL_FAX_SUCCESS",
  CANCEL_FAX_FAIL = "[OCR] CANCEL_FAX_FAIL",

  EXPORT_ORDERS_POS = "[Orders] EXPORT_ORDERS_POS",
  EXPORT_ORDERS_POS_SUCCESS = "[Orders] EXPORT_ORDERS_POS_SUCCESS",
  EXPORT_ORDERS_POS_FAIL = "[Orders] EXPORT_ORDERS_POS_FAIL",

  IS_HIDE_PRICE_TOTAL = '[Orders] IS_HIDE_PRICE_TOTAL',
}

export const getRevenueInformation = (payload: IGetRevenueInformationPayload): IAction<any> => ({
  type: OrderActionTypes.GET_REVENUEINFORMATION,
  payload,
});

export const getRevenueInformationSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_REVENUEINFORMATION_SUCCESS,
  payload,
});

export const getRevenueInformationFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_REVENUEINFORMATION_FAIL,
  error,
});

export const getOrders = (payload: IGetOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS,
  payload,
});

export const getOrdersSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS_SUCCESS,
  payload,
});

export const getOrdersFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS_FAIL,
  error,
});

export const getOrder = (payload: IGetOrderPayload): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER,
  payload,
});

export const getOrderSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER_SUCCESS,
  payload,
});

export const getOrderFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER_FAIL,
  error,
});


export const changeOrderStatus = (payload: IChangeOrderStatusPayload): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_STATUS,
  payload,
});

export const changeOrderReturnStatus = (payload: IChangeOrderReturnStatusPayload): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_RETURN_STATUS,
  payload,
});

export const changeOrderStatusSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_STATUS_SUCCESS,
  payload,
});

export const changeOrderReturnStatusSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_RETURN_STATUS_SUCCESS,
  payload,
});

export const changeOrderStatusFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_STATUS_FAIL,
  error,
});

export const changeOrderReturnStatusFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_RETURN_STATUS_FAIL,
  error,
});

export const exportOrdersHistories = (payload: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_HISTORIES,
  payload,
});

export const exportOrdersHistoriesSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_HISTORIES_SUCCESS,
  payload,
});

export const exportOrdersHistoriesFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_HISTORIES_FAIL,
  error,
});

export const exportOrdersV2 = (payload: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_V2,
  payload,
});

export const exportOrdersV2Success = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_V2_SUCCESS,
  payload,
});

export const exportOrdersV2Fail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_V2_FAIL,
  error,
});

export const changeOrderProductStatus = (payload: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_PRODUCT_STATUS,
  payload,
});

export const changeOrderProductStatusSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_PRODUCT_STATUS_SUCCESS,
  payload,
});

export const changeOrderProductStatusFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CHANGE_ORDER_PRODUCT_STATUS_FAIL,
  error,
});

export const getOrdersByCustomer = (payload: IGetOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS_BY_CUSTOMER,
  payload,
});

export const getOrdersByCustomerSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS_BY_CUSTOMER_SUCCESS,
  payload,
});

export const getOrdersByCustomerFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDERS_BY_CUSTOMER_FAIL,
  error,
});

export const exportOrders = (payload: IExportOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS,
  payload,
});

export const exportOrdersSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_SUCCESS,
  payload,
});

export const exportOrdersFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_FAIL,
  error,
});

export const exportSales = (payload: IExportOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SALES,
  payload,
});

export const exportSalesSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SALES_SUCCESS,
  payload,
});

export const exportSalesFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SALES_FAIL,
  error,
});

export const updateOrder = (payload: IUpdateOrderPayload): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER,
  payload,
});

export const updateOrderSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER_SUCCESS,
  payload,
});

export const updateOrderFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER_FAIL,
  error,
});

export const updateOrderProducts = (payload: IUpdateOrderProuctsPayload): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER_PRODUCTS,
  payload,
});

export const updateOrderProductsSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER_PRODUCTS_SUCCESS,
  payload,
});

export const updateOrderProductsFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.UPDATE_ORDER_PRODUCTS_FAIL,
  error,
});

export const exportPurchaseOrders = (payload: IExportPurchaseOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.EXPORT_PURCHASE_ORDERS,
  payload,
});

export const exportPurchaseOrdersSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_PURCHASE_ORDERS_SUCCESS,
  payload,
});

export const exportPurchaseOrdersFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_PURCHASE_ORDERS_FAIL,
  error,
});
export const exportSakayasInfoSummary = (): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SAKAYA_INFO_SUMMARY,
});

export const exportSakayasInfoSummarySuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SAKAYA_INFO_SUMMARY_SUCCESS,
  payload,
});

export const exportSakayasInfoSummaryFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_SAKAYA_INFO_SUMMARY_FAIL,
  error,
});

export const resendOrderConfirmation = (id: string): IAction<any> => ({
  type: OrderActionTypes.RESEND_ORDER_CONFIRMATION,
  payload: {id: id},
});

export const resendOrderConfirmationSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.RESEND_ORDER_CONFIRMATION_SUCCESS,
  payload,
});

export const resendOrderConfirmationFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.RESEND_ORDER_CONFIRMATION_FAIL,
  error,
});

export const getPurchasedProducts = (customerId: string): IAction<any> => ({
  type: OrderActionTypes.GET_PURCHASED_PRODUCTS,
  payload : {customerId: customerId},
});

export const getPurchasedProductsSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_PURCHASED_PRODUCTS_SUCCESS,
  payload,
});

export const getPurchasedProductsFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_PURCHASED_PRODUCTS_FAIL,
  error,
});

export const sendProductRejectedNotification = (orderId: string): IAction<any> => ({
  type: OrderActionTypes.SEND_PRODUCTS_REJECTED_NOTIFICATION,
  payload : {orderId: orderId},
});

export const sendProductRejectedNotificationSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.SEND_PRODUCTS_REJECTED_NOTIFICATION_SUCCESS,
  payload,
});

export const sendProductRejectedNotificationFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.SEND_PRODUCTS_REJECTED_NOTIFICATION_FAIL,
  error,
});

export const saveFirebaseToken = (token: string): IAction<any> => ({
  type: OrderActionTypes.SAVE_FIREBASE_TOKEN,
  payload : {token: token},
});

export const saveFirebaseTokenSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.SAVE_FIREBASE_TOKEN_SUCCESS,
  payload,
});

export const saveFirebaseTokenFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.SAVE_FIREBASE_TOKEN_FAIL,
  error,
});

export const getOrderSummary = (payload: IGetOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER_SUMMARY,
  payload,
});

export const getOrderSummarySuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER_SUMMARY_SUCCESS,
  payload,
});

export const getOrderSummaryFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_ORDER_SUMMARY_FAIL,
  error,
});

export const getSakayasInfoSummary = (): IAction<any> => ({
  type: OrderActionTypes.GET_SAKAYAS_INFO_SUMMARY,
});

export const getSakayasInfoSummarySuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_SAKAYAS_INFO_SUMMARY_SUCCESS,
  payload,
});

export const getSakayasInfoSummaryFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_SAKAYAS_INFO_SUMMARY_FAIL,
  error,
});

export const generateProductsPdf = (payload: IGenerateProductsPdf): IAction<any> => ({
  type: OrderActionTypes.GENERATE_PRODUCTS_SELECTED_PDF,
  payload,
});

export const generateProductsPdfSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GENERATE_PRODUCTS_SELECTED_PDF_SUCCESS,
  payload,
});

export const generateProductsPdfFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GENERATE_PRODUCTS_SELECTED_PDF_FAIL,
  error,
});

export const createSelectedCollection = (payload: IGenerateProductsPdf): IAction<any> => ({
  type: OrderActionTypes.CREATE_SELECTED_OPTIONS,
  payload,
});

export const createSelectedCollectionSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CREATE_SELECTED_OPTIONS_SUCCESS,
  payload,
});

export const createSelectedCollectionFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CREATE_SELECTED_OPTIONS_FAIL,
  error,
});

export const deleteSelectedCollection = (payload: string): IAction<any> => ({
  type: OrderActionTypes.DELETE_SELECTED_OPTIONS,
  payload,
});

export const deleteSelectedCollectionSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.DELETE_SELECTED_OPTIONS_SUCCESS,
  payload,
});

export const deleteSelectedCollectionFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.DELETE_SELECTED_OPTIONS_FAIL,
  error,
});

export const getProductsSelected = (payload: string): IAction<any> => ({
  type: OrderActionTypes.GET_PRODUCTS_SELECTED,
  payload,
});

export const getProductsSelectedSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_PRODUCTS_SELECTED_SUCCESS,
  payload,
});

export const getProductsSelectedFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_PRODUCTS_SELECTED_FAIL,
  error,
});

export const getFaxFiles = (payload: boolean): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_FILES,
  payload,
});

export const getFaxFilesSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_FILES_SUCCESS,
  payload,
});

export const getFaxFilesFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_FILES_FAIL,
  error,
});

export const getFaxContent = (payload: string): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_CONTENT,
  payload,
});

export const getFaxContentSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_CONTENT_SUCCESS,
  payload,
});

export const getFaxContentFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.GET_FAX_CONTENT_FAIL,
  error,
});

export const cancelFax = (payload: string): IAction<any> => ({
  type: OrderActionTypes.CANCEL_FAX,
  payload,
});

export const cancelFaxSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CANCEL_FAX_SUCCESS,
  payload,
});

export const cancelFaxFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CANCEL_FAX_FAIL,
  error,
});

export const scanFaxImages = (payload: any): IAction<any> => ({
  type: OrderActionTypes.SCAN_FAX_IMAGES,
  payload,
});

export const scanFaxImagesSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.SCAN_FAX_IMAGES_SUCCESS,
  payload,
});

export const scanFaxImagesFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.SCAN_FAX_IMAGES_FAIL,
  error,
});

export const createOrder = (payload: any): IAction<any> => ({
  type: OrderActionTypes.CREATE_ORDER,
  payload,
});

export const createOrderSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.CREATE_ORDER_SUCCESS,
  payload,
});

export const createOrderFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.CREATE_ORDER_FAIL,
  error,
});

export const exportOrdersPos = (payload: IExportPosOrdersPayload): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_POS,
  payload,
});

export const exportOrdersPosSuccess = (payload?: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_POS_SUCCESS,
  payload,
});

export const exportOrdersPosFail = (error: any): IAction<any> => ({
  type: OrderActionTypes.EXPORT_ORDERS_POS_FAIL,
  error,
});

export const setIsHidePriceTotal = (isHidePrice: boolean): IAction<any> => ({
  type: OrderActionTypes.IS_HIDE_PRICE_TOTAL,
  payload: isHidePrice,
})