import { IAction, IState } from "../../../../shared/interfaces/common";
import { ProductActionTypes } from "../../actions/products/product.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const deletePriceReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case ProductActionTypes.DELETE_PRICE: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case ProductActionTypes.DELETE_PRICE_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case ProductActionTypes.DELETE_PRICE_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
        default: {
            return state;
        }
    }
}
