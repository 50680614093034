import { combineEpics } from "redux-observable";
import { getCustomersEpic } from "./getCustomersEpic";
import { getCustomerEpic } from "./getCustomerEpic";
import { getCustomerGroupsEpic } from "./getCustomerGroupsEpic";
import { exportCustomersEpic } from "./exportCustomersEpic";
import { createAddressEpic } from "./createAddressEpic";
import { importHorecaInformationEpic } from "./importHorecaInformationEpic";
import { exportQRCodesEpic } from "./exportQRCodesEpic";
import { pauseOrResumeCustomerEpic } from "./pauseOrResumeCustomerEpic";
import { updateCustomerHidePriceEpic } from "./updateCustomerHidePriceEpic";
import { importHidePriceEpic } from "./importHidePriceEpic";
import { getCustomerHidePriceUploadHistoryEpic } from "./getCustomerHidePriceUploadHistoryEpic";
import { getHidePriceFileEpic } from "./getHidePriceFileEpic";
import { updateCustomerAddressEpic } from "./updateCustomerAddressEpic";

export const customersEpic = combineEpics(
  getCustomersEpic,
  getCustomerEpic,
  getCustomerGroupsEpic,
  exportCustomersEpic,
  createAddressEpic,
  pauseOrResumeCustomerEpic,
  importHorecaInformationEpic,
  exportQRCodesEpic,
  updateCustomerHidePriceEpic,
  importHidePriceEpic,
  getCustomerHidePriceUploadHistoryEpic,
  getHidePriceFileEpic,
  updateCustomerAddressEpic,
);
