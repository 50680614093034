import { IUseCase } from '../../../../shared/interfaces/common/usecase';
import ValidationResult from '../../../../models/validationResult';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';

export default class GetNotAssignStockStatusCase implements IUseCase {
    private customerRepository: CustomerRepository;

    constructor() {
        this.customerRepository = new CustomerRepository();
    }

    validate = (): ValidationResult => {
            return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.customerRepository.getNotAssignStockStatusData()
                    .then((response: any) => {
                        const data = response.result;
                        if (data) {
                            resolve(data);
                        } else {
                            reject(ResponseModel.createError(500, response.errors[0].message));
                        }
                    })
                    .catch((error: any) => {
                        reject(ResponseModel.createError(500, JSON.stringify(error)));
                    });
            }
        });
    };
}
