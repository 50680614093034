import {IUseCase} from '../../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';
import ValidationResult from '../../../../models/validationResult';
import Cookies from 'js-cookie';

export default class AddUserUseCase implements IUseCase {
    private customerRepository: CustomerRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.customerRepository = new CustomerRepository();
    }

    validate = (): ValidationResult => {
        return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.customerRepository.addUser(this.payload).then((response: any) => {
                    // console.log('Create USER RESPONSE===', response.data);
                    if (response.data.success) {
                        
                        resolve(response.data);
                        
                    } else {
                        reject(ResponseModel.createError(500, response.data.errorMessage.slice(0, 24)));
                    }
                }).catch(reject);
            }
        });

    };

}
