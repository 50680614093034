import {
  IAddSaleRepresentativePayload,
  IDeleteSalesPayload,
  IGetSaleRepresentativePayload,
  IUpdateSalePayload,
} from "../../../presentation/redux/actions/interfaces/saleInfo.types";
import SaleCloud from "./cloud";

export default class SaleRepository {
  public getSalesRepresentatives = (payload: IGetSaleRepresentativePayload) => {
    return SaleCloud.getSalesRepresentatives(payload);
  };

  public addSaleRepresentative = (payload: IAddSaleRepresentativePayload) => {
    return SaleCloud.addSaleRepresentative(payload);
  };

  public importSale = (payload: any) => {
    return SaleCloud.importSale(payload);
  };

  public deleteSales = (payload: IDeleteSalesPayload) => {
    return SaleCloud.deleteSales(payload);
  };

  public updateSale = (payload: IUpdateSalePayload) => {
    return SaleCloud.updateSale(payload);
  };
}
