import { ofType } from "redux-observable";
import {
  CustomerActionTypes,
  getHidePriceFileFail,
  getHidePriceFileSuccess,
} from "../../actions/customers/customer.actions";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { GetHidePriceFileUseCase } from "../../../../domain/usecases/customer";

export const getHidePriceFileEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CustomerActionTypes.GET_HIDE_PRICE_FILE),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new GetHidePriceFileUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(getHidePriceFileSuccess(data.data));

            obs.complete();
          })
          .catch((error) => {
            obs.next(getHidePriceFileFail(error.message));

            obs.complete();
          });
      });
    })
  );
