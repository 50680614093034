import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import { SettingCustomProductUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, settingCustomProductSuccess, settingCustomProductFail } from "../../actions/products/product.actions";


export const settingCustomProductEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SETTING_CUSTOM_PRODUCT),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SettingCustomProductUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    notifySuccess('Setting custom product for customer success！');
                    obs.next(settingCustomProductSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(settingCustomProductFail(error.message));
                    obs.complete();
                });
            });
        })
    );
