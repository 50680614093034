import React from 'react'
import Loading from '../../presentation/components/preloader/Loading'
import ScaleLoader from 'react-spinners/ScaleLoader'

const FullLoading = () => {
    return (
        <div className='flex justify-center items-center absolute h-full w-full'>
            <ScaleLoader
                color="#3f83f8"
                loading={true}
                height={25}
                width={3}
                radius={3}
                margin={4}
            />
        </div>
    )
}

export default FullLoading