import SaleRepository from "../../../../data/repository/sale";
import ResponseModel from "../../../../models/response";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";

export default class ImportSaleUseCase implements IUseCase {
  private saleRepository: SaleRepository;

  constructor(public payload: any) {
    this.saleRepository = new SaleRepository();
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.saleRepository
        .importSale(this.payload)
        .then((response: any) => {
          if (response.data && response.data.data && response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(reject);
    });
  };
}
