import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { NotificationActionTypes, importCustomerTagsFail, importCustomerTagsSuccess } from "../../actions/notification/notification.actions";
import { ImportCustomerTagsUseCase } from "../../../../domain/usecases/notification";

export const importCustomerTagsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(NotificationActionTypes.IMPORT_CUSTOMER_TAGS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ImportCustomerTagsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(importCustomerTagsSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(importCustomerTagsFail(error));
                    obs.complete();
                });
            });
        })
    );


