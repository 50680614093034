import { combineEpics } from "redux-observable";
import { searchInvoicesEpic } from "./searchInvoicesEpic";
import { createInvoiceEpic } from "./createInvoiceEpic";
import { createInvoiceVersionEpic } from "./createInvoiceVersionEpic";
import { deleteInvoiceEpic } from "./deleteInvoiceEpic";
import { restoreInvoiceEpic } from "./restoreInvoiceEpic";
import { downloadInvoiceEpic } from "./downloadInvoiceEpic";
import { sendInvoiceReminderEpic } from "./sendInvoiceReminderEpic";
import { createBulkUploadJobEpic } from "./createBulkUploadJobEpic";
import { searchDeletedInvoicesEpic } from "./searchDeletedInvoicesEpic";
import { searchDraftInvoicesEpic } from "./searchDraftInvoicesEpic";
import { hasPendingJobEpic } from "./hasPendingJobEpic";
import { publishInvoicesEpic } from "./publishInvoicesEpic";
import { getBulkUploadHistoriesEpic } from "./getBulkUploadHistoriesEpic";
import { switchInvoiceStatusEpic } from "./switchInvoiceStatusEpic";
import { getSignedUrlEpic } from "./getSignedUrlEpic";
import { completeUploadEpic } from "./completeUploadEpic";
import { getInvoiceDetailEpic } from "./getInvoiceDetailEpic";
import { getInvoiceListEpic } from "./getInvoiceListEpic";
import { downloadInvoiceBillEpic } from "./downloadInvoiceBillEpic";

export const invoicesEpic = combineEpics(
  searchInvoicesEpic,
  createInvoiceEpic,
  createInvoiceVersionEpic,
  deleteInvoiceEpic,
  restoreInvoiceEpic,
  downloadInvoiceEpic,
  sendInvoiceReminderEpic,
  createBulkUploadJobEpic,
  searchDeletedInvoicesEpic,
  searchDraftInvoicesEpic,
  hasPendingJobEpic,
  publishInvoicesEpic,
  getBulkUploadHistoriesEpic,
  switchInvoiceStatusEpic,
  getSignedUrlEpic,
  completeUploadEpic,
  getInvoiceDetailEpic,
  getInvoiceListEpic,
  downloadInvoiceBillEpic
);
