import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { UpdateOrderProductsUseCase } from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, updateOrderProductsSuccess, updateOrderProductsFail } from "../../actions/orders/order.actions";
import { notifySuccess } from "../../../../utils/toast";
export const updateOrderProductsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.UPDATE_ORDER_PRODUCTS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new UpdateOrderProductsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('注文の更新が完了!');
                    obs.next(updateOrderProductsSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(updateOrderProductsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


