import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import GetUserDetailUseCase from "../../../../domain/usecases/users/getUserDetail";
import { IAction } from "../../../../shared/interfaces/common";
import { UserActionTypes, getUserDetailSuccess, getUserDetailFail } from "../../actions/users/user.actions";


export const getUserDetailEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.GET_USER_DETAIL),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetUserDetailUseCase(payload.id);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getUserDetailSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getUserDetailFail(error.message));
                    obs.complete();
                });
            });
        })
    );


