import { IGetRevenueInformationPayload, IChangeOrderStatusPayload, IGetOrderPayload, IGetOrdersPayload, IExportOrdersPayload, IUpdateOrderPayload, IUpdateOrderProuctsPayload, IExportPurchaseOrdersPayload, IGenerateProductsPdf, IExportPosOrdersPayload, IChangeOrderReturnStatusPayload } from '../../../presentation/redux/actions/interfaces/order.types';
import OrderCloud from './cloud';
import CmsCloud from './cms';

// import CustomerStorage from './storage';

export default class OrderRepository {

    public getOrder = (payload: IGetOrderPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getOrder(payload);
    };

    public getOrders = (payload: IGetOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getOrders(payload);
    };

    public getRevenueInformation = (payload: IGetRevenueInformationPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getRevenueInformation(payload);
    };

    public changeOrderStatus = (payload: IChangeOrderStatusPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.changeOrderStatus(payload);
    };

    public changeOrderReturnStatus = (payload: IChangeOrderReturnStatusPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.changeOrderReturnStatus(payload);
    };

    public exportOrdersHistories = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportOrdersHistories(payload);
    };

    public changeOrderProductStatus = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.changeOrderProductStatus(payload);
    };

    public getOrderData = () => {
        return CmsCloud.getOrderData();
    };

    public getDeliverySlotsData = () => {
        return CmsCloud.getDeliverySlotsData();
    };

    public exportOrders = (payload: IExportOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportOrders(payload);
    };
    public exportOrdersV2 = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportOrdersV2(payload);
    };
    public exportSales = (payload: IExportOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportSales(payload);
    };

    public updateOrder = (payload: IUpdateOrderPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.updateOrder(payload);
    };

    public updateOrderProducts = (payload: IUpdateOrderProuctsPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.updateOrderProducts(payload);
    };

    public exportPurchaseOrders = (payload: IExportPurchaseOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportPurchaseOrders(payload);
    };

    public exportSakayasInfoSummary = () => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportSakayasInfoSummary();
    };

    public resendOrderConfirmation = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.resendOrderConfirmation(payload);
    };

    public getPurchasedProducts = (customerId: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getPurchasedProducts(customerId);
    };

    public sendProductRejectedNotification = (orderId: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.sendProductRejectedNotification(orderId);
    };

    public saveFirebaseToken = (token: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.saveFirebaseToken(token);
    };

    public getOrderSummary = (payload: IGetOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getOrderSummary(payload);
    };

    public getSakayasInfoSummary = () => {
        // Consider call api or get data from localStorage
        return OrderCloud.getSakayasInfoSummary();
    };

    public generateProductsPdf = (payload: IGenerateProductsPdf) => {
        // Consider call api or get data from localStorage
        return OrderCloud.generateProductsPdf(payload);
    };

    public createSelectedCollection = (payload: IGenerateProductsPdf) => {
        // Consider call api or get data from localStorage
        return OrderCloud.createSelectedCollection(payload);
    };

    public deleteCollectedOption = (id: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.deleteCollectedOption(id);
    };

    public getProductsSelected = (customerId: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getProductsSelected(customerId);
    };

    public getFaxFiles = (includeCompleted: boolean) => {
        // Consider call api or get data from localStorage
        return OrderCloud.getFaxFiles(includeCompleted);
    };

    public faxContent = (faxId: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.faxContent(faxId);
    };

    public cancelFax = (faxId: string) => {
        // Consider call api or get data from localStorage
        return OrderCloud.cancelFax(faxId);
    };

    public scanFaxImages = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.scanFaxImages(payload);
    };

    public createOrder = (payload: any) => {
        // Consider call api or get data from localStorage
        return OrderCloud.createOrder(payload);
    };

    public exportOrdersPos = (payload: IExportPosOrdersPayload) => {
        // Consider call api or get data from localStorage
        return OrderCloud.exportOrdersPos(payload);
    };
}
