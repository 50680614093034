import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { GetProductSettingsUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { ProductActionTypes, getProductSettingsSuccess, getProductSettingsFail } from "../../actions/products/product.actions";
import ApiHelper from "../../../../data/helper/ApiHelper";


export const getProductSettingsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.GET_PRODUCT_SETTINGS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetProductSettingsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = ApiHelper.getPrices(responses.data);
                    obs.next(getProductSettingsSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getProductSettingsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


