import ResponseModel from "../../../../models/response";
import {
  IAddSaleRepresentativePayload,
  IDeleteSalesPayload,
  IGetSaleRepresentativePayload,
  IUpdateSalePayload,
} from "../../../../presentation/redux/actions/interfaces/saleInfo.types";
import ApiGateway from "../../../gateway/api";
import { SitecoreResource } from "../../../gateway/api/resource";
import { getConfig } from "../../../setting/graphConfig";

const getSalesRepresentatives = (
  payload: IGetSaleRepresentativePayload
): Promise<ResponseModel<string>> => {
  const apiGateway = ApiGateway.createAPIConnection(getConfig());
  const saleResource = SitecoreResource.Sale.GetSalesRepresentatives(payload);

  return apiGateway.doGetRequest(saleResource);
};

const addSaleRepresentative = (
  payload: IAddSaleRepresentativePayload
): Promise<ResponseModel<string>> => {
  const apiGateway = ApiGateway.createAPIConnection(getConfig());

  const saleResource = SitecoreResource.Sale.AddSaleRepresentative();

  return apiGateway.doPostRequest(saleResource, payload);
};

const importSale = (payload: any): Promise<ResponseModel<string>> => {
  const apiGateway = ApiGateway.createAPIConnection(getConfig());

  const saleResource = SitecoreResource.Sale.ImportSale();

  return apiGateway.doPostRequest(saleResource, payload);
};

const deleteSales = (
  payload: IDeleteSalesPayload
): Promise<ResponseModel<string>> => {
  const apiGateway = ApiGateway.createAPIConnection(getConfig());

  const saleResource = SitecoreResource.Sale.DeleteSales();

  return apiGateway.doPutRequest(saleResource, payload);
};

const updateSale = (
  payload: IUpdateSalePayload
): Promise<ResponseModel<string>> => {
  const apiGateway = ApiGateway.createAPIConnection(getConfig());

  const saleResource = SitecoreResource.Sale.UpdateSale();

  return apiGateway.doPutRequest(saleResource, payload);
};

export default {
  getSalesRepresentatives,
  addSaleRepresentative,
  importSale,
  deleteSales,
  updateSale,
};
