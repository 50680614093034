import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import { DeleteCustomerGroupPrice } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, getPriceCustomerGroupsSuccess, deleteCustomerGroupPriceSuccess, deleteCustomerGroupPriceFail } from "../../actions/products/product.actions";


export const deleteCustomerGroupPriceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.DELETE_CUSTOMER_GROUP_PRICE),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new DeleteCustomerGroupPrice(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;

                    const customerGroupPriceData = state$.value.productsReducer.getPriceCustomerGroupsReducer.data;

                    const newCustomerGroupPriceData = customerGroupPriceData?.filter((item: any) => item.id !== payload.id);
                    if (newCustomerGroupPriceData) {
                        obs.next(getPriceCustomerGroupsSuccess(newCustomerGroupPriceData));
                    }
                    notifySuccess('特別価格を削除しました。!');
                    obs.next(deleteCustomerGroupPriceSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(deleteCustomerGroupPriceFail(error.message));
                    obs.complete();
                });
            });
        })
    );
