import { IResource } from '../../../shared/interfaces/common/resource';
import {request} from 'graphql-request';


class GraphqlGateway {
  _configTimeout = 3600000;

  _endpoint: string;

  _accessToken: string;

  _adminUsername: string;

  _adminPassword: string;

  _googleApiKey: string;

  _resource?: IResource;

  static createAPIConnection(settings: any) {
    return new GraphqlGateway(settings);
  }

  constructor(settings: any) {
    const {
      endpoint,
      accessToken,
      headers,
      adminUsername,
      adminPassword,
      googleApiKey,
    } = settings;
    this._endpoint = endpoint;
    this._adminUsername = adminUsername;
    this._adminPassword = adminPassword;
    this._accessToken = accessToken;
    this._googleApiKey = googleApiKey;
  }

  getEndpoint = (resourceType: string) => {
    return this._endpoint;
  };

  

  doGraphqlRequest = async (query: string, resource: IResource): Promise<any> =>{
    const endpoint = this.getEndpoint(resource.Type);
    return await request(endpoint, query, null, {
      authorization: this._accessToken,
    });
  }

}

export default GraphqlGateway;
