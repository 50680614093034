import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import ProductRepository from '../../../data/repository/product';
import ValidationResult from '../../../models/validationResult';
import { ISetActivePricePayload } from '../../../presentation/redux/actions/interfaces/product.types';

export default class SetDiscontinuedPriceUseCase implements IUseCase {
    private productRepository: ProductRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.productRepository = new ProductRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.productRepository.setDiscontinuedPrice(this.payload).then((response: any) => {
                //console.log('getOrderUseCase:::2', response.data);
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
