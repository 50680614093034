import { IAction, IState } from "../../../../shared/interfaces/common";
import { SaleRepresentativeType } from "../../actions/interfaces/saleInfo.types";
import { SaleActionTypes } from "../../actions/sales/sale.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<any> = ReducerHelper.baseReducer();

export const getSalesRepresentativesReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case SaleActionTypes.GET_SALES_REPRESENTATIVES: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case SaleActionTypes.GET_SALES_REPRESENTATIVES_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case SaleActionTypes.GET_SALES_REPRESENTATIVES_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    case SaleActionTypes.ON_ADD_SALES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          data: {
            ...state.data.data,
            total: state.data.data.total + 1,
            result: [action.payload.data, ...state.data.data.result],
          },
        },
      };
    }

    case SaleActionTypes.ON_UPDATE_SALE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          data: {
            ...state.data.data,
            result: state.data.data.result.map((item: SaleRepresentativeType) =>
              item.id === action.payload.id ? action.payload : item
            ),
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
