import Type from '../../type';
import {getApiController} from '../../resource';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { ISingleInvoicePayload, ISearchInvoicesPayload, ICreateBulkInvoiceJobPayload, IPublishInvoicesPayload, IGetBulkUploadHistoriesPayload, ISwitchInvoiceStatusPayload, IGetSignedUrlPayload, ICompleteUploadPayload, IGetInvoicePayload, IGetInvoiceListPayload, IDownloadInvoiceBillPayload } from '../../../../../presentation/redux/actions/interfaces/invoices.types';

const SearchInvoices = (payload: ISearchInvoicesPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/SearchInvoices?page=${payload.page}&pageSize=${payload.pageSize}&keyword=${payload.keyword}&startDate=${payload.startDate ?? ''}&endDate=${payload.endDate ?? ''}&pics=${payload.pics ?? ''}&status=${payload.status ?? ''}&isSearchDraft=${payload.isSearchDraft ?? 'false'}&isSearchDeleted=${payload.isSearchDeleted ?? 'false'}`
});

const HasPendingJob = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/HasJobPending`
});

const CreateInvoice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/CreateInvoice`
});

const CreateInvoiceVersion = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/CreateInvoiceVersion`
});

const DeleteInvoice = (payload: ISingleInvoicePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/DeleteInvoice?invoiceId=${payload.invoiceId}`
});

const RestoreInvoice = (payload: ISingleInvoicePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/RestoreInvoice`
});

const DownloadInvoice = (payload: ISingleInvoicePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/DownloadInvoice?invoiceHistoryId=${payload.invoiceId}`
});

const SendInvoiceReminder = (payload: ISingleInvoicePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/SendInvoiceReminder`
});

const CreateBulkUploadJob = (payload: ICreateBulkInvoiceJobPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/CreateBulkUploadJob?fileName=${payload.fileName}`
});

const PublishInvoices = (payload: IPublishInvoicesPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/PublishInvoices`
});

const GetBulkUploadHistories = (payload: IGetBulkUploadHistoriesPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/GetBulkUploadHistories?page=${payload.page}&pageSize=${payload.pageSize}&startDate=${payload.startDate ?? ''}
    &endDate=${payload.endDate ?? ''}`
});

const SwitchInvoiceStatus = (payload: ISwitchInvoiceStatusPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/SwitchInvoiceStatus`
});

const GetSignedUrl = (payload: IGetSignedUrlPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/GetSignedUrl?fileName=${payload.fileName}&size=${payload.size}`
});

const CompleteUpload = (payload: ICompleteUploadPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/invoices/v1.0/CompleteUpload`
});

const GetInvoiceDetail = (payload: IGetInvoicePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/billing/v1.0/Summary?month=${payload.month}&year=${payload.year}`
});

const GetInvoiceList = (payload: IGetInvoiceListPayload, storeView?: string): IResource => {
    let url = `${getApiController(storeView)}/management/billing/v1.0/List?months=${payload.listMonths}&keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`
    if(payload.downloadStatus != undefined){
        url = url + `&downloadStatus=${payload.downloadStatus}`
    }
    return {
        Type: Type.Customer,
        Path: url
    }
};

const DownloadInvoiceBill = (payload: IDownloadInvoiceBillPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/billing/v1.0/GetInvoiceDownloadLink?ids=${payload.ids}`
});


export default {
    SearchInvoices,
    CreateInvoice,
    CreateInvoiceVersion,
    DeleteInvoice,
    RestoreInvoice,
    DownloadInvoice,
    SendInvoiceReminder,
    CreateBulkUploadJob,
    HasPendingJob,
    PublishInvoices,
    GetBulkUploadHistories,
    SwitchInvoiceStatus,
    GetSignedUrl,
    CompleteUpload,
    GetInvoiceDetail,
    GetInvoiceList,
    DownloadInvoiceBill
};