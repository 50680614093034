import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import {  GetOverrideDisplayUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError } from "../../../../utils/toast";
import { ProductActionTypes, getOverrideDisplaySuccess, getOverrideDisplayFail } from "../../actions/products/product.actions";


export const getOverrideDisplayEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.GET_OVERRIDE_DISPLAY_PRODUCT),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetOverrideDisplayUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    obs.next(getOverrideDisplaySuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(getOverrideDisplayFail(error.message));
                    obs.complete();
                });
            });
        })
    );


