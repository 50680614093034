import { ofType } from "redux-observable";
import {
  ProductActionTypes,
  getProductsUploadHistory,
  getProductsUploadHistorySuccess,
  getProductsUploadHistoryFail
} from "../../actions/products/product.actions";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { Observable } from "rxjs";
import { GetProductsUploadHistoryUseCase } from "../../../../domain/usecases/products";

export const getProductsUploadHistoryEpic = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(ProductActionTypes.GET_PRODUCTS_UPLOAD_HISTORY),
    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new GetProductsUploadHistoryUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(getProductsUploadHistorySuccess(data));

            obs.complete();
          })
          .catch((error) => {
            obs.next(getProductsUploadHistoryFail(error.message));

            obs.complete();
          });
      });
    })
  );
