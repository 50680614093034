import Type from '../../type';
// import { getApiController } from '../';
import { IResource } from '../../../../../shared/interfaces/common/resource';

const GetOrders = (storeView?: string): IResource => ({
  Type: Type.Public,
  Path: `/orders`,
});



export default {
  GetOrders,
};
