import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import LoginUserUseCase from "../../../../domain/usecases/users/loginUser";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { UserActionTypes, userLoginSuccess, userLoginFail } from "../../actions/users/user.actions";


export const userLoginEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.USER_LOGIN),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new LoginUserUseCase(payload.email, payload.password);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('ログイン成功!');
                    obs.next(userLoginSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(userLoginFail(error.message));
                    obs.complete();
                });
            });
        })
    );


