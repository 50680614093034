import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, hasPendingJobFail, hasPendingJobSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import HasPendingJobUseCase from "../../../../domain/usecases/invoices/HasPendingJobUseCase";


export const hasPendingJobEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.HAS_PENDING_JOB),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const usecase = new HasPendingJobUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(hasPendingJobSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(hasPendingJobFail(error.message));
                    obs.complete();
                });
            });
        })
    );


