import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderOnBehalfActionTypes, createOnBehalfOrderSuccess,createOnBehalfOrderFail } from "../../actions/orderonbehalf/orderonbehalf.actions";
import CreateOnBehalfOrdersUseCase from "../../../../domain/usecases/orderonbehalf/createOnBehalfOrder";


export const createOrderOnBehalfEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CreateOnBehalfOrdersUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(createOnBehalfOrderSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(createOnBehalfOrderFail(error));
                    obs.complete();
                });
            });
        })
    );


