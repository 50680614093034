import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { TokenType } from '../../../data/constants';

interface IProps {
  children: any;
}

const PrivateRoute = ({ children, ...rest }: IProps) => {
  const [isLogin, setIsLogin] = React.useState(() => !!localStorage.getItem(TokenType.AccessToken));

  if(!isLogin){
    return <Redirect exact from='/' to="/login" />
  }

  return (
    <Route
      {...rest}
      render={() =>
        children
      }
    />
  );
};

export default PrivateRoute;
